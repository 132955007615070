// fonts
// @font-face {
//     font-family: 'Roboto Flex', sans-serif;
//     src: url('../fonts/Roboto_Flex/static/RobotoFlex/RobotoFlex-Regular.ttf');
//     font-display: swap;
// }

.font-gothic-a1 {
  font-family: "Gothic A1", sans-serif;
}
.font-ubuntu {
  font-family: "Ubuntu", sans-serif;
}

body {
  background: transparent !important;
  line-height: normal;
  @extend .font-gothic-a1;
  @media only screen and (max-width: 1200px) {
    font-size: 12px;
  }
}

.butler-300 {
  @extend .font-gothic-a1;
  font-weight: 300;
}

// width
.is-700 {
  font-weight: 700 !important;
}
.is-400 {
  font-weight: 400 !important;
}
.is-600 {
  font-weight: 600 !important;
}
.is-500 {
  font-weight: 500 !important;
}
.is-300 {
  font-weight: 300 !important;
}

// Colors
$white: #fff;
$offwhite: #f5f8ff;
$blue: #3c1e95;
$blue2: #415da1;
$dark-blue: #141e30;
$light-blue: #009fda;
$lightest-blue: #eef9ff;
$black: #0e0e0d;
$black2: #444444;
$gray: #edf2ff;
$gray2: #979797;
$gray3: #6b6b6b;
$dark-gray: #000;
$light-gray: #e4e4e4;
$light-gray2: #b5b5b5;
$lightest-gray: #fff8f8;
$orange: #fda452;
$red: #ff4d4d;
$green: #1fa72c;

//Color
.is-white {
  color: $white;
}
.is-black2 {
  color: $black2;
}
.is-blue2 {
  color: $blue2;
}
.is-offwhite {
  color: $offwhite;
}
.is-gray3 {
  color: $gray3;
}
.is-gray2 {
  color: $gray2;
}
.is-black {
  color: $black;
}
.is-blue {
  color: $blue;
}
.is-dark-blue {
  color: $dark-blue;
}
.is-light-blue {
  color: $light-blue;
}
.is-lightest-blue {
  color: $lightest-blue;
}
.is-orange {
  color: $orange;
}
.is-gray {
  color: $gray;
}
.is-dark-gray {
  color: $dark-gray;
}
.is-light-gray {
  color: $light-gray;
}
.is-lightest-gray {
  color: $lightest-gray;
}

// Background Color
.is-bg-transparent {
  background-color: transparent;
}
.is-bg-white {
  background-color: $white !important;
}
.is-bg-dark {
  background-color: $black;
}
.is-bg-blue {
  background-color: $blue;
}
.is-bg-dark-blue {
  background-color: $dark-blue;
}
.is-bg-light-blue {
  background-color: $light-blue;
}
.is-bg-lightest-blue {
  background-color: $lightest-blue;
}
.is-bg-orange {
  background-color: $orange;
}
.is-bg-gray {
  background-color: $gray;
}
.is-bg-dark-gray {
  background-color: $dark-gray;
}
.is-bg-light-gray {
  background-color: $light-gray;
}
.is-bg-lightest-gray {
  background-color: $lightest-gray;
}

// background gradient
.is-black-gradient {
  background: linear-gradient(90deg, #0e0e0d 0%, #0e0e0d 97.79%);
}
.is-blue-gradient {
  background: linear-gradient(90deg, #3c1e95 0%, #4e2daa 100%);
}
$is-blue-gradient2: linear-gradient(90.37deg, #805ee6 0.33%, #a993eb 97.7%);
$is-blue-gradient3: linear-gradient(90.37deg, #805ee6 0.33%, #a993eb 97.7%);
.is-blue-gradient2 {
  background: linear-gradient(90.37deg, #4626a4 0.33%, #937dd6 97.7%);
}
.is-blue-gradient3 {
  background: linear-gradient(90.37deg, #805ee6 0.33%, #a993eb 97.7%);
}
.is-blue-gradient-opacity-08 {
  background: linear-gradient(90deg, #004e79cc 0%, #3582accc 97.79%);
}
.is-orange-gradient {
  background: linear-gradient(89.92deg, #e8842d 0.06%, #f9a256 99.93%);
}
.is-orange-gradient-opacity-08 {
  background: linear-gradient(89.92deg, #e8842dcc 0.06%, #f9a256cc 99.93%);
}
.is-top-gradient {
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 29%;
    z-index: 1;
  }
  &.is-black-grd {
    &:before {
      background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    }
  }
}
.is-bottom-gradient {
  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 40%;
    z-index: 1;
  }
  &.is-blue-grd {
    &:after {
      background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
      transform: matrix(1, 0, 0, -1, 0, 0);
    }
  }
  &.is-light-blue-grd {
    &:after {
      background: linear-gradient(180deg, #141e3000 50%, #141e30cc 100%);
    }
  }
}

.is-underlined {
  text-decoration: underline;
}

// container
.container {
  width: 85%;
  margin: 0 auto;
  &.cont-is-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.is-justify-space-between {
      justify-content: space-between;
    }
  }
  &.extra-small {
    width: 1140px !important;
    max-width: none !important;
  }
  @media screen and (min-width: 1701px) {
    &.fullhd {
      max-width: 90% !important;
      max-width: 1600px !important;
    }
    &.extrahd {
      width: 85% !important;
      max-width: none !important;
    }
  }
  @media screen and (max-width: 1700px) {
    &.fullhd {
      width: 90% !important;
      // max-width: 1600px !important;
    }
  }
  @media screen and (max-width: 1200px) {
    &.extra-small {
      width: 90% !important;
      max-width: none !important;
    }
  }
  @media screen and (min-width: 1024px) {
    max-width: none !important;
  }
}

// width
.full-width {
  width: 100%;
}
// 4 side padding
.padding-01 {
  padding: 15px !important;
}

// section alignment
.top-bottom-padding {
  padding-top: 5vw;
  padding-bottom: 5vw;
  &.small-padding {
    padding-top: 3vw;
    padding-bottom: 3vw;
  }
  &.big-padding {
    padding: 11.3vw 0;
  }
  @media only screen and (max-width: 1700px) {
    // padding-top: 4vw;
    // padding-bottom: 4vw;
    &.small-padding {
      // padding-top: 1vw;
      // padding-bottom: 1vw;
    }
  }
  @media only screen and (max-width: 1023px) {
    padding-top: 70px;
    padding-bottom: 70px;
    &.small-padding {
      padding-top: 50px;
      padding-bottom: 50px;
    }
    &.mob-top-padding-0 {
      padding-top: 0;
    }
    &.mob-bottom-padding-0 {
      padding-bottom: 0;
    }
  }
}
.bottom-padding {
  padding-bottom: 3vw;
}

// button animate
.button {
  &.type-01 {
    // background-color: transparent;
    // border: 1px solid rgba(255, 255, 255, 0.5);
    // border-radius: 30px;
    // padding: 26px;
    padding: 29.5px 45.5px;
    // box-shadow: 0px 9px 24px rgba(106, 83, 162, 0.37);
    border-radius: 8px;
    // font-size: 16px;
    // position: relative;
    // overflow: hidden;
    // @extend .transition-all-1s-ease;
    &:before {
      // content: '';
      // width: 120%;
      // height: 100%;
      // position: absolute;
      // left: -10%;
      // top: 100%;
      // z-index: 1;
      // @extend .transition-all-03s-ease;
    }
    span {
      position: relative;
      z-index: 2;
    }
    &.is-white {
      color: $blue;
      border-color: $white;
      &:before {
        background-color: $white;
      }
    }
    &.is-black {
      color: $black;
      border-color: $black;
      &:before {
        background-color: $black;
      }
    }
    &.is-blue {
      color: $white;
      border-color: $blue;
      background-color: $blue;
      &:before {
        background-color: $blue;
      }
    }
    &.is-filled-white {
      color: $blue;
      border-color: $blue;
      background-color: $white;
      &:before {
        background-color: $white;
      }
    }
    &.radius-4 {
      border-radius: 4px;
    }
    &.is-gray {
      color: $gray;
      border-color: $gray;
      background-color: transparent;
      &:before {
        background-color: $red;
      }
    }
    &.is-font-blue {
      color: $blue;
      border: 1px solid $blue;
      background-color: $white;
    }
    &.is-black-fill {
      color: $white;
      border-color: $black;
      background-color: $black;
      &:before {
        color: $black;
        border-color: $orange;
        background-color: $orange;
      }
    }
    &.is-white-fill {
      color: $offwhite;
      border-color: $white;
      background-color: $white;
      &:before {
        color: $white;
        border-color: $red;
        background-color: $red;
      }
    }
    &.is-red-fill {
      color: $white;
      border-color: $red;
      background-color: $red;
      &:before {
        color: $red;
        border-color: $orange;
        background-color: $orange;
      }
    }
    &.is-bold {
      font-weight: 700;
    }
  }
  &.type-02 {
    background-color: transparent;
    border: none;
    padding: 0;
    font-size: 16px;
    width: 7.5vw;
    height: 7.5vw;
    display: flex;
    align-items: center;
    position: relative;
    @extend .transition-all-03s-ease;
    &:before {
      content: "";
      width: 100%;
      height: 100%;
      border: 1px solid rgba(255, 255, 255, 0.5);
      border-radius: 50%;
      position: absolute;
      left: -35%;
      top: 0;
      @extend .transition-all-03s-ease;
    }
    span {
      position: relative;
      z-index: 1;
    }
    &.is-white {
      color: $white;
    }
  }
  &.type-03 {
    background-color: transparent;
    border: none;
    padding: 0;
    height: auto;
    .text {
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 2px;
    }
    .line-arrow {
      position: relative;
      display: flex;
      align-items: center;
      .span-1 {
        content: "";
        background-color: $red;
        width: 50px;
        height: 1px;
        display: inline-block;
        margin: 0 10px;
      }
      .span-2 {
        width: 0px;
        height: 0px;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: 10px solid $red;
        display: inline-block;
      }
    }
    &.is-red {
      .text {
        color: $red;
      }
    }
  }
  &:hover {
    &.type-01 {
      color: $white;
      &.is-white {
        color: $white;
        background: $is-blue-gradient3;
        border: rgba(147, 125, 214, 1);
        &:before {
          top: 0;
        }
      }
      &.is-font-blue {
        color: $white;
        background: $is-blue-gradient2;
        border-color: rgba(147, 125, 214, 1);
        &:before {
          top: 0;
        }
      }
      &.is-blue {
        color: $white;
        background: $is-blue-gradient2;
        border-color: rgba(147, 125, 214, 1);
        &:before {
          top: 0;
        }
      }
      &.is-gray {
        color: $white;
        &:before {
          top: 0;
        }
      }
    }
    &.type-02 {
      &:before {
        border-color: $red;
        background-color: $red;
      }
      &.is-white {
        color: $white;
      }
    }
  }
  @media only screen and (max-width: 1700px) {
    &.type-01 {
      padding: 21px 24px;
      font-size: 15px;
    }
    &.type-03 {
      .text {
        // font-size: 12px;
      }
    }
  }
  @media only screen and (max-width: 1680px) {
    &.type-01 {
      padding: 18px 20px;
      font-size: 14px;
    }
  }
  @media only screen and (max-width: 1200px) {
    &.type-01 {
      padding: 17px 19px;
      font-size: 12px;
    }
  }
  @media only screen and (max-width: 768px) {
    &.type-01 {
      padding: 15px 19px;
    }
  }
}

.popup-button {
  margin: 10px;
  padding: 1em 3em;
  background-size: 200% auto !important;
  color: white;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  // background-image: linear-gradient(to right, 249deg, #1DF3E1 0%, #1E99FB 100%);
  transition: 0.5s;
  &:hover {
    background-position: right center;
  }
}

// arrow-button
.btn-big-cricle-wrp {
  border-radius: 50%;
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  // width: 210px;
  // height: 210px;
  width: 11vw;
  height: 11vw;
  &.big-size {
    // width: 15.3vw;
    // height: 15.3vw;
    width: 13.3vw;
    height: 13.3vw;
  }
  &.small-size {
    width: 7vw;
    height: 7vw;
  }
  &.white {
    border-color: $white;
  }
  &.blue {
    border-color: $blue;
    background-color: transparent;
  }
  &.blue-bg {
    border-color: $blue;
    background-color: $blue;
  }
  // arrow
  .down-arrow {
    &:before,
    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }
    &:before {
      width: 3px;
      height: 34%;
      z-index: 1;
      margin: auto;
    }
    &:after {
      border-style: solid;
      border-width: 0 3px 3px 0;
      display: inline-block;
      width: 14%;
      height: 14%;
      transition: all 0.3s ease;
      transform: translate(0%, 58%) rotate(45deg);
      z-index: 1;
    }
    &.white {
      &:before {
        background-color: $white;
      }
      &:after {
        border-color: $white;
      }
    }
    &.blue {
      &:before {
        background-color: $blue;
      }
      &:after {
        border-color: $blue;
      }
    }
  }
  .right-arrow {
    transform: rotate(-90deg);
    width: 100%;
    height: 100%;
    &:before,
    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }
    &:before {
      width: 3px;
      height: 34%;
      z-index: 1;
      margin: auto;
    }
    &:after {
      border-style: solid;
      border-width: 0 3px 3px 0;
      display: inline-block;
      width: 14%;
      height: 14%;
      transition: all 0.3s ease;
      transform: translate(0%, 58%) rotate(45deg);
      z-index: 1;
    }
    &.white {
      &:before {
        background-color: $white;
      }
      &:after {
        border-color: $white;
      }
    }
    &.blue {
      &:before {
        background-color: $blue;
      }
      &:after {
        border-color: $blue;
      }
    }
  }
  // text
  .text {
    @extend .font-gothic-a1;
    &.white {
      color: $white;
    }
    &.blue {
      color: $blue;
    }
  }
  // position
  &.verticle-center {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(50%, -50%);
    &.left {
      left: 0;
      right: inherit;
      transform: translate(-50%, -50%);
    }
  }
  &.horizontal-center {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    &.top {
      right: 0;
      left: inherit;
      transform: translate(50%, -50%);
    }
  }
  .read-more-btn {
    background-color: transparent;
    width: 42px;
    height: 42px;
    font-size: 0;
    border-radius: 50%;
    border: 1px solid #fff;
    // position: absolute;
    bottom: 0;
    // transform: translateY(-50%);
    transition: 0.6s cubic-bezier(0.14, 1, 0.34, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      margin: 0 auto;
      width: 8px;
      height: 8px;
      border: 2px solid #fff;
      // right: 28%;
      // right: 5px;
      // left: 0;
      top: 50%;
      left: 50%;
      transform: translate(0, -50%) rotate(-45deg);
      border-top: none;
      border-left: none;
    }
    &:after {
      content: "";
      background-color: #fff;
      position: absolute;
      margin: 0 auto;
      width: 16px;
      height: 2px;
      // right: 5px;
      // left: 0;
      top: 50%;
      transform: translate(0, -50%);
    }
    &.white {
      background-color: #fff;
      &:before {
        border-color: #1e99fb;
      }
      &:after {
        background-color: #1e99fb;
      }
    }
    &.gradient-btn {
      border: none;
      background: transparent linear-gradient(225deg, #1df3e1 0%, #1e99fb 100%)
        0% 0% no-repeat padding-box;
    }
    @media screen and (max-width: 1700px) {
      width: 34px;
      height: 34px;
      &:before {
        width: 7px;
        height: 7px;
      }
      &:after {
        width: 12px;
        height: 2px;
      }
    }
  }
  &.blue-btn {
    .read-more-btn {
      background-color: $blue;
    }
  }
  &.white-btn {
    .read-more-btn {
      background-color: $white;
      &:before {
        border-color: $blue;
      }
      &:after {
        background-color: $blue;
      }
    }
  }
  &:hover {
    .read-more-btn {
      border: 1px solid #fff !important;
      &:before {
        animation: leftToRightArrowBefore 1s ease;
      }
      &:after {
        animation: leftToRightArrowAfter 1s ease;
      }
    }
  }
  @keyframes rigthToleftArrowAnm {
    //     0%{
    //         transform: translate(0) rotate(180deg);
    //         opacity: 1;
    //     }
    //     45%{
    //         transform: translate(-40px) rotate(180deg);
    //         opacity: 1;
    //     }
    //     46%{
    //         transform: translate(-40px) rotate(180deg);
    //         opacity: 0;
    //     }
    //     54%{
    //         transform: translate(40px) rotate(180deg);
    //         opacity: 0;
    //     }
    //     55%{
    //         transform: translate(40px) rotate(180deg);
    //         opacity: 1;
    //     }
    //     100%{
    //         transform: translate(0) rotate(180deg);
    //         opacity: 1;
    //     }
  }
  @media only screen and (max-width: 1200px) {
    // width: 9vw;
    // height: 9vw;
    &.big-size {
      // width: 13.3vw;
      // height: 13.3vw;
    }
    .read-more-btn {
      width: 26px;
      height: 26px;
      &:before {
        width: 6px;
        height: 6px;
      }
      &:after {
        width: 9px;
        height: 2px;
      }
    }
  }
  @media only screen and (max-width: 1023px) {
    width: 16vw;
    height: 16vw;
    &.big-size {
      width: 18.3vw;
      height: 18.3vw;
    }
    &.small-size {
      width: 100px;
      height: 100px;
    }
    &.verticle-center {
      left: 0;
      transform: translate(-50%, -50%);
      &.left {
        left: inherit;
        right: 0;
        transform: translate(50%, -50%);
      }
    }
    .read-more-btn {
      &:before {
        border-width: 1px;
        left: 46%;
      }
      &:after {
        height: 1px;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    width: 85px;
    height: 85px;
    &.big-size {
      width: 100px;
      height: 100px;
    }
    .is-para {
      font-size: 12px;
    }
    .read-more-btn {
      &:before {
        border-width: 1px;
        left: 46%;
      }
      &:after {
        height: 1px;
      }
    }
  }
}

// arrow-text-button
.btn-wrp {
  .read-more-btn {
    background-color: transparent;
    width: 42px;
    height: 42px;
    font-size: 0;
    border-radius: 50%;
    border: 1px solid #fff;
    // position: absolute;
    bottom: 0;
    // transform: translateY(-50%);
    transition: 0.6s cubic-bezier(0.14, 1, 0.34, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      margin: 0 auto;
      width: 8px;
      height: 8px;
      border: 2px solid #fff;
      // right: 28%;
      // right: 5px;
      // left: 0;
      top: 50%;
      left: 50%;
      transform: translate(0, -50%) rotate(-45deg);
      border-top: none;
      border-left: none;
    }
    &:after {
      content: "";
      background-color: #fff;
      position: absolute;
      margin: 0 auto;
      width: 16px;
      height: 2px;
      // right: 5px;
      // left: 0;
      top: 50%;
      transform: translate(0, -50%);
    }
    &.white {
      background-color: #fff;
      &:before {
        border-color: #1e99fb;
      }
      &:after {
        background-color: #1e99fb;
      }
    }
    &.gradient-btn {
      border: none;
      background: transparent linear-gradient(225deg, #1df3e1 0%, #1e99fb 100%)
        0% 0% no-repeat padding-box;
    }
    @media screen and (max-width: 1700px) {
      width: 34px;
      height: 34px;
      &:before {
        width: 7px;
        height: 7px;
      }
      &:after {
        width: 12px;
        height: 2px;
      }
    }
  }
  .read-more-text {
    @extend .butler-300;
    font-size: 22px;
    text-align: left;
    letter-spacing: normal;
    margin-left: 10px;
  }
  &.blue-btn {
    .read-more-btn {
      background-color: $blue;
    }
    .read-more-text {
      color: $blue;
    }
  }
  &.white-btn {
    .read-more-btn {
      background-color: $white;
      &:before {
        border-color: $blue;
      }
      &:after {
        background-color: $blue;
      }
    }
    .read-more-text {
      color: $white;
    }
  }
  &.white-border-btn {
    .read-more-btn {
      background-color: transparent;
      &:before {
        border-color: $white;
      }
      &:after {
        background-color: $white;
      }
    }
    .read-more-text {
      color: $white;
    }
  }
  &.blue-border-btn {
    .read-more-btn {
      background-color: transparent;
      border-color: $blue;
      &:before {
        border-color: $blue;
      }
      &:after {
        background-color: $blue;
      }
    }
    .read-more-text {
      color: $blue;
    }
  }
  &:hover {
    .read-more-btn {
      border: 1px solid #fff !important;
      &:before {
        animation: leftToRightArrowBefore 1s ease;
      }
      &:after {
        animation: leftToRightArrowAfter 1s ease;
      }
    }
  }
  @keyframes rigthToleftArrowAnm {
    //     0%{
    //         transform: translate(0) rotate(180deg);
    //         opacity: 1;
    //     }
    //     45%{
    //         transform: translate(-40px) rotate(180deg);
    //         opacity: 1;
    //     }
    //     46%{
    //         transform: translate(-40px) rotate(180deg);
    //         opacity: 0;
    //     }
    //     54%{
    //         transform: translate(40px) rotate(180deg);
    //         opacity: 0;
    //     }
    //     55%{
    //         transform: translate(40px) rotate(180deg);
    //         opacity: 1;
    //     }
    //     100%{
    //         transform: translate(0) rotate(180deg);
    //         opacity: 1;
    //     }
  }
  @media only screen and (max-width: 1700px) {
    .read-more-text {
      font-size: 16px;
      margin-left: 8px;
    }
  }
  @media only screen and (max-width: 1200px) {
    .read-more-btn {
      width: 26px;
      height: 26px;
      &:before {
        width: 6px;
        height: 6px;
      }
      &:after {
        width: 9px;
        height: 2px;
      }
    }
  }
  @media only screen and (max-width: 1023px) {
    .read-more-btn {
      &:before {
        border-width: 1px;
        left: 46%;
      }
      &:after {
        height: 1px;
      }
    }
  }
}

.btn-underline-wrp {
  @extend .heading-03;
  text-align: left;
  border-bottom: 1px solid $dark-gray;
  &.white {
    color: $white;
  }
  &.dark-gray {
    color: $dark-gray;
  }
  &.blue {
    color: $blue;
  }
  @media screen and (max-width: 1700px) {
    font-size: 12px;
    margin-left: 7px;
  }
  @media screen and (max-width: 1200px) {
    font-size: 11px;
  }
}

// remove-btn
.remove-btn {
  position: absolute;
  top: 0;
  right: 0;
  .button {
    margin: 0;
    padding: 0 10px;
    height: auto;
  }
}

@keyframes leftToRightArrowBefore {
  0% {
    transform: translate(0, -50%) rotate(-45deg);
    opacity: 1;
  }
  45% {
    transform: translate(40px, -50%) rotate(-45deg);
    opacity: 1;
  }
  46% {
    transform: translate(40px, -50%) rotate(-45deg);
    opacity: 0;
  }
  54% {
    transform: translate(-40px, -50%) rotate(-45deg);
    opacity: 0;
  }
  55% {
    transform: translate(-40px, -50%) rotate(-45deg);
    opacity: 1;
  }
  100% {
    transform: translate(0, -50%) rotate(-45deg);
    opacity: 1;
  }
}

@keyframes leftToRightArrowAfter {
  0% {
    transform: translate(0, -50%);
    opacity: 1;
  }
  45% {
    transform: translate(40px, -50%);
    opacity: 1;
  }
  46% {
    transform: translate(40px, -50%);
    opacity: 0;
  }
  54% {
    transform: translate(-40px, -50%);
    opacity: 0;
  }
  55% {
    transform: translate(-40px, -50%);
    opacity: 1;
  }
  100% {
    transform: translate(0, -50%);
    opacity: 1;
  }
}

//gallery-icon
.gallery-icon {
  width: 25px;
  height: 21px;
}

// Background Btn
.bg-btn {
  font-weight: 500;
  padding: 25px 20px;
  cursor: pointer;
  &.bg-blue {
    background-color: $blue;
    color: $white;
  }
  &.bg-orange {
    background-color: $orange;
    color: $white;
    border: none;
  }
  &.bg-white {
    background-color: $white;
    color: $blue;
    border: none;
  }
  &.small-padding {
    padding: 14px 15px;
  }
  &:hover {
    &.bg-blue {
      background-color: $orange;
      color: $blue;
    }
    &.bg-orange {
      background-color: $blue;
      color: $orange;
    }
  }
  @media screen and (max-width: 1700px) {
    padding: 20px 16px;
    svg {
      width: 13px;
    }
    &.small-padding {
      padding: 4.5px 8px;
    }
  }
  @media screen and (max-width: 1200px) {
    padding: 16px 12px;
    &.small-padding {
      padding: 4.5px 8px;
    }
  }
}

// Border Btn
.border-btn {
  border-width: 1px;
  // border-style: solid;
  font-weight: 500;
  padding: 25px 20px;
  background-color: transparent;
  @extend .transition-all-03s-ease;
  &.border-blue {
    border-color: $blue;
  }
  &.border-orange {
    border-color: $orange;
  }
  &.border-light-gray {
    border-color: $light-gray;
  }
  &.is-icon {
    span {
      display: inline-block;
      margin-right: 15px;
    }
    &.icon-left {
      span {
        margin-right: 0;
        margin-left: 15px;
      }
    }
  }
  &:hover {
    &.border-blue {
      border-color: $blue;
      background-color: $blue;
      color: $white;
    }
    &.border-orange {
      border-color: $orange;
      background-color: $orange;
      color: $white;
      path {
        fill: $white;
      }
    }
    &.border-light-gray {
      border-color: $dark-gray;
      background-color: transparent;
    }
    &.is-icon {
      svg {
        fill: $white;
      }
    }
  }
  @media screen and (max-width: 1700px) {
    padding: 20px 16px;
    &.is-icon {
      span {
        margin-right: 12px;
      }
      svg {
        width: 20px;
      }
      &.icon-left {
        span {
          margin-left: 12px;
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
    padding: 16px 12px;
    &.is-icon {
      span {
        margin-right: 8px;
      }
      svg {
        width: 16px;
      }
      &.icon-left {
        span {
          margin-left: 8px;
        }
      }
    }
  }
}

.owl-dots {
  text-align: center;
  display: none;
}

.slider-btn-circle {
  &.owl-theme .owl-nav {
    margin-top: 0;
    [class*="owl-"] {
      background-color: transparent;
      width: 72px;
      height: 72px;
      font-size: 0;
      border-radius: 50%;
      border: 1px solid #fff;
      position: absolute;
      top: 45%;
      // left: 6%;
      transform: translateY(-50%);
      transition: 0.6s cubic-bezier(0.14, 1, 0.34, 1);
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      &:before {
        content: "";
        position: absolute;
        margin: 0 auto;
        width: 13px;
        height: 13px;
        border: 2px solid #fff;
        // right: 28%;
        // right: 5px;
        // left: 0;
        top: 50%;
        transform: translate(0, -50%) rotate(-45deg);
        border-top: none;
        border-left: none;
      }
      &:after {
        content: "";
        background-color: #fff;
        position: absolute;
        margin: 0 auto;
        width: 26px;
        height: 2px;
        // right: 5px;
        // left: 0;
        top: 50%;
        transform: translate(0, -50%);
      }
      &.disabled {
        // display: none;
      }
      &:focus {
        outline: none;
      }
      &:not(.disabled):hover {
        &:before {
          animation: btn-arrow-before 1s ease;
        }
        &:after {
          animation: btn-arrow-after 1s ease;
        }
      }
      @keyframes btn-arrow-before {
        0% {
          transform: translate(0, -50%) rotate(-45deg);
        }
        50% {
          transform: translate(40px, -50%) rotate(-45deg);
        }
        51% {
          transform: translate(-40px, -50%) rotate(-45deg);
        }
        100% {
          transform: translate(0, -50%) rotate(-45deg);
        }
      }
      @keyframes btn-arrow-after {
        0% {
          transform: translate(0, -50%);
        }
        50% {
          transform: translate(40px, -50%);
        }
        51% {
          transform: translate(-40px, -50%);
        }
        100% {
          transform: translate(0, -50%);
        }
      }
    }
    .owl-prev {
      left: -7.5%;
      transform: translateY(-50%) rotate(180deg);
      &:before {
        right: 30%;
      }
      &:after {
      }
    }
    .owl-next {
      right: -7.5%;
      &:before {
        right: 30%;
      }
      &:after {
        // right: 6px;
      }
    }
  }
  &.dark-gray {
    &.owl-theme .owl-nav [class*="owl-"] {
      border-color: $dark-gray;
      &:before {
        border-color: $dark-gray;
      }
      &:after {
        background-color: $dark-gray;
      }
    }
  }
  &.blue {
    &.owl-theme .owl-nav [class*="owl-"] {
      border-color: $blue;
      &:before {
        border-color: $blue;
      }
      &:after {
        background-color: $blue;
      }
    }
  }
  &.trans-bg-color-black {
    &.owl-theme .owl-nav [class*="owl-"] {
      color: #000;
      background: transparent;
      top: 50%;
      &:not(.disabled):hover {
        color: #fff;
        background: green;
      }
    }
  }
  &.right-bottom {
    padding-bottom: 10vw;
    &.owl-theme .owl-nav {
      [class*="owl-"] {
        top: inherit;
        bottom: 0%;
      }
      .owl-prev {
        left: inherit;
        right: 90px;
      }
      .owl-next {
        right: 0;
      }
    }
  }
  &.left-bottom {
    padding-bottom: 10vw;
    &.owl-theme .owl-nav {
      [class*="owl-"] {
        top: inherit;
        bottom: 0%;
      }
      .owl-prev {
        left: 0px;
      }
      .owl-next {
        right: inherit;
        left: 90px;
      }
    }
  }
  @media screen and (max-width: 1700px) {
    &.owl-theme .owl-nav {
      [class*="owl-"] {
        width: 52px;
        height: 52px;
        &:before {
          width: 7px;
          height: 7px;
        }
        &:after {
          width: 12px;
          height: 2px;
        }
      }
      .owl-prev {
        left: -4.5%;
        &:before {
          right: 35%;
        }
      }
      .owl-next {
        right: -4.5%;
        &:before {
          right: 35%;
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
    // &.owl-theme {
    //   .owl-nav {
    //     [class*="owl-"] {
    //       width: 42px;
    //       height: 42px;
    //       top: -36px;
    //       &:before {
    //         width: 6px;
    //         height: 6px;
    //       }
    //       &:after {
    //         width: 9px;
    //         height: 2px;
    //       }
    //     }
    //   }
    // }
    // &.owl-carousel .owl-nav .owl-prev {
    //   left: inherit;
    //   right: 42px;
    // }
    // &.owl-carousel .owl-nav .owl-next {
    //   left: inherit;
    //   right: 0;
    // }
  }
  @media only screen and (max-width: 767px) {
    &.owl-theme {
      .owl-nav {
        [class*="owl-"] {
          // top: -25px;
        }
      }
    }
  }

  .circle-button.owl-theme .owl-nav .owl-next:hover:before {
    animation: leftToRightArrowAnm 0.5s ease;
  }

  .circle-button.owl-theme .owl-nav .owl-prev:hover:before {
    animation: rigthToleftArrowAnm 0.5s ease;
  }

  @keyframes leftToRightArrowAnm {
    0% {
      transform: translate(0);
      opacity: 1;
    }
    45% {
      transform: translate(40px);
      opacity: 1;
    }
    46% {
      transform: translate(40px);
      opacity: 0;
    }
    54% {
      transform: translate(-40px);
      opacity: 0;
    }
    55% {
      transform: translate(-40px);
      opacity: 1;
    }
    100% {
      transform: translate(0);
      opacity: 1;
    }
  }

  @keyframes rigthToleftArrowAnm {
    0% {
      transform: translate(0) rotate(180deg);
      opacity: 1;
    }
    45% {
      transform: translate(-40px) rotate(180deg);
      opacity: 1;
    }
    46% {
      transform: translate(-40px) rotate(180deg);
      opacity: 0;
    }
    54% {
      transform: translate(40px) rotate(180deg);
      opacity: 0;
    }
    55% {
      transform: translate(40px) rotate(180deg);
      opacity: 1;
    }
    100% {
      transform: translate(0) rotate(180deg);
      opacity: 1;
    }
  }
}

// Border
.border {
  border-width: 1px;
  border-style: solid;
  &.is-gray {
    border-color: $gray;
  }
  &.is-blue {
    border-color: $blue;
  }
}
.border-bottom {
  border-bottom: 1px solid $orange;
  padding-bottom: 20px;
  &.light-gray {
    border-bottom-color: $light-gray;
  }
  &.padding-b-auto {
    padding-bottom: auto;
  }
  @media only screen and (max-width: 1700px) {
    padding-bottom: 15px;
  }
  @media only screen and (max-width: 768px) {
    padding-bottom: 12px;
  }
}
.border-none {
  border: none !important;
}

// transition
.transition-all-03s-ease {
  transition: all 0.3s ease;
}

// date
.event-date {
  position: absolute;
  background-color: white;
  width: 50px;
  height: 50px;
  line-height: normal;
  // border: 1px solid black;
  border-radius: 50%;
  display: flex;
  align-items: center;
  text-align: center;
  color: black;
  top: 5px;
  right: 6px;
  font-size: 10px;
  padding: 10px;
  color: $dark-gray;
  &.big-img-event-date {
    width: 60px;
    height: 60px;
    font-size: 12px;
    top: 20px;
    right: 22px;
  }
  &.event-section-date {
    // left: 290px;
  }
  @media only screen and (max-width: 1500px) {
    // width: 40px;
    // height: 40px;
    // &.big-img-event-date{
    //     width: 50px;
    //     height: 50px;
    // }
    // &.event-section-date{
    //     left: 240px;
    //     top: 10px;
    // }
  }

  @media only screen and (max-width: 1024px) {
    left: 260px;
    &.big-img-event-date {
      left: 340px !important;
    }
    &.event-section-date {
      left: 190px;
      font-size: 10px;
      width: 40px;
      height: 40px;
    }
  }

  @media only screen and (max-width: 768px) {
    left: 350px;
    &.big-img-event-date {
      left: 460px !important;
    }
    &.event-section-date {
      left: 580px;
      font-size: 14px;
      width: 60px;
      height: 60px;
    }
  }

  @media only screen and (max-width: 767px) {
    &.event-section-date {
      left: 410px;
    }
  }

  @media only screen and (max-width: 480px) {
    left: 280px;
    &.big-img-event-date {
      left: 280px !important;
    }
    &.event-section-date {
      left: 210px;
      width: 40px;
      height: 40px;
      font-size: 10px;
    }
  }
}

//Select
.select {
  &:not(.is-multiple) {
    height: 50px;
  }
  &.is-primary {
    select {
      height: 50px;
      border-color: #e7e7e7;
      font-size: 15px;
      padding: 10px 10px 10px 10px !important;
      color: #7e7979;
    }
  }
  &:not(.is-multiple):not(.is-loading)::after {
    border-width: 2px;
    border-color: #7e7979;
    height: 12px;
    width: 12px;
    top: 46%;
  }
  &.border-select {
    select {
      background-color: transparent;
    }
    &.orange {
      select {
        border-color: $orange;
        color: $light-gray;
      }
    }
    &:not(.is-multiple):not(.is-loading)::after {
      // border-color: $white;
    }
  }
  &.border-bottom {
    select {
      background-color: transparent;
      border: none;
      // border-bottom: 1px solid;
      box-shadow: none;
      @extend .font-gothic-a1;
    }
    &.dark-gray {
      select {
        border-color: $dark-gray;
        color: $dark-gray;

        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: $dark-gray;
        }
        &::-moz-placeholder {
          /* Firefox 19+ */
          color: $dark-gray;
        }
        &:-ms-input-placeholder {
          /* IE 10+ */
          color: $dark-gray;
        }
        &:-moz-placeholder {
          /* Firefox 18- */
          color: $dark-gray;
        }
      }
    }
    &.gray {
      select {
        border-color: $gray;
        color: $gray;

        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: $gray;
        }
        &::-moz-placeholder {
          /* Firefox 19+ */
          color: $gray;
        }
        &:-ms-input-placeholder {
          /* IE 10+ */
          color: $gray;
        }
        &:-moz-placeholder {
          /* Firefox 18- */
          color: $gray;
        }
      }
    }
    &.white {
      select {
        border-color: $white;
        color: $white;

        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: $white;
        }
        &::-moz-placeholder {
          /* Firefox 19+ */
          color: $white;
        }
        &:-ms-input-placeholder {
          /* IE 10+ */
          color: $white;
        }
        &:-moz-placeholder {
          /* Firefox 18- */
          color: $white;
        }
      }
    }
  }
  &.big {
    &:not(.is-multiple) {
      height: 70px;
    }
    select {
      font-size: 25px;
      height: 70px;
    }
  }
  @media only screen and (max-width: 1700px) {
    &:not(.is-multiple) {
      height: 32px;
    }
    &.is-primary {
      select {
        height: 32px;
        line-height: initial;
        font-size: 13px;
        padding: 0 4px 0 8px !important;
      }
    }
    &:not(.is-multiple):not(.is-loading)::after {
      height: 10px;
      width: 10px;
    }
    select {
      height: 30px;
      padding-top: 0;
    }
    &.big {
      &:not(.is-multiple) {
        height: 50px;
      }
      select {
        font-size: 15px;
        height: 50px;
      }
    }
  }
  @media only screen and (max-width: 1200px) {
    &.is-primary {
      select {
        font-size: 12px;
        padding: 0 4px 0 8px !important;
      }
    }
    &:not(.is-multiple):not(.is-loading)::after {
      height: 8px;
      width: 8px;
      border-width: 1px;
    }
  }
}

//Input
.input {
  &:not(.is-multiple) {
    height: 50px;
  }
  &.border-bottom {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid;
    box-shadow: none;
    &.dark-gray {
      border-color: $dark-gray;
      color: $dark-gray;

      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: $dark-gray;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: $dark-gray;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: $dark-gray;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: $dark-gray;
      }
    }
    &.gray {
      border-color: $gray;
      color: $gray;

      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: $gray;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: $gray;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: $gray;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: $gray;
      }
    }
    &.white {
      border-color: $white;
      color: $white;

      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: $white;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: $white;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: $white;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: $white;
      }
    }
  }
  @media screen and (max-width: 1700px) {
    font-size: 12px;
    &:not(.is-multiple) {
      height: 32px;
    }
  }
}
.input-group {
  background: transparent !important;
}

// label
.label:not(:last-child) {
  @media screen and (max-width: 1700px) {
    margin-bottom: 0em;
  }
}

// Radio, Checkbox
.radio,
.checkbox {
  display: inline-flex;
  align-items: center;
  input {
    margin-right: 4px;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    margin-right: 0px;
    margin-left: 0 !important;
  }
}
.radio {
  + .radio {
    margin-left: 1.5em;
  }
}
.checkbox {
  + .checkbox {
    margin-left: 1.5em;
  }
}

// form-btn
.form-btn-wrp {
  .container {
    // padding: 0 12%;
    padding: 0 6%;
  }
  .column {
    &:last-child {
      .btn-big-cricle-wrp {
        margin: 5% 0 0 auto;
      }
    }
  }
  .file .input-group {
    display: none;
  }
  @media screen and (max-width: 1700px) {
    .container {
      padding: 0 12%;
    }
  }
  @media screen and (max-width: 767px) {
    .container {
      padding: 0 5%;
    }
  }
}
// form-btn

// file
.file {
  &.has-name {
    .file-cta {
      width: 35%;
    }
    .file-name {
      width: 65%;
      max-width: none;
    }
  }
  &.file-01 {
    width: 100%;
    .file-label {
      width: 100%;
      height: 70px;
    }
    .file-name {
      height: 100%;
    }
    .file-cta {
      width: 100%;
      height: 100%;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 22px;
      @extend .font-gothic-a1;
      color: $blue;
      font-weight: 400;
    }
  }
  @media screen and (max-width: 1700px) {
    &.file-01 {
      .file-label {
        height: 50px;
      }
      .file-cta {
        font-size: 16px;
      }
    }
  }
  @media screen and (max-width: 1023px) {
    &.file-01 {
      margin-top: 0 !important;
      .file-label {
        height: 40px;
      }
      .file-cta {
        // font-size: 14px;
      }
    }
  }
}

//Hide content
.hide-content-2-line {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  -moz-line-clamp: 2;
}
.hide-content-3-line {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  -moz-line-clamp: 3;
}

hr {
  margin: 1vw 0;
  @media screen and (max-width: 1200px) {
    margin: 1rem 0;
  }
}

// sort-by
.sort-by {
  .container {
    > div {
      display: flex;
      justify-content: flex-end;
      // align-items: center;
      > div {
        margin-left: 20px;
        cursor: pointer;
        &:last-child {
          margin-left: 40px;
        }
        path {
          fill: $gray;
        }
        p {
          color: $gray;
        }
        &.active {
          path {
            fill: $orange;
          }
          p {
            // color: $orange;
          }
        }
      }
    }
  }
  .select {
    &.is-primary {
      select {
        // width: 110px;
        padding-right: 43px !important;
      }
    }
  }
}

// heading

.heading-01 {
  font-size: 3.13vw;
  font-weight: 600;
  &.is-black {
    color: $black2;
  }
  @media only screen and (max-width: 1023px) {
    // font-size: 7.1vw;
    // line-height: 7.1vw;
    &.medium {
      // font-size: 6.2vw;
      // line-height: 6.2vw;
    }
  }
  @media only screen and (max-width: 767px) {
    font-size: 5.5vw;
    text-align: center;
    &.medium {
    }
  }
  @media only screen and (max-width: 700px) {
    font-size: 5vw;
    line-height: 6.5vw;
  }
}
.heading-02 {
  font-size: 4.2vw;
  font-weight: 600;
  line-height: 5.5vw;
  &.is-blue {
    color: $blue;
  }
  &.is-white {
    color: $white;
  }
  @media only screen and (max-width: 1700px) {
    // font-size: 3.7vw;
    // line-height: 5.4vw;
  }
  @media only screen and (max-width: 1400px) {
    .heading-02 {
      font-size: 4vw;
      line-height: 5.3vw;
    }
  }
  @media only screen and (max-width: 767px) {
    // font-size: 14px;
  }
  @media only screen and (max-width: 700px) {
    font-size: 6vw;
    line-height: 6.5vw;
    text-align: center;
  }
}
.heading-03 {
  font-size: 2.6vw;
  font-weight: 700;
  &.is-blue {
    color: $blue;
  }
  &.is-white {
    color: $white;
  }
  @media only screen and (max-width: 1700px) {
    font-size: 2.2vw;
  }
  @media only screen and (max-width: 1200px) {
    font-size: 35px;
  }
  @media only screen and (max-width: 767px) {
    font-size: 30px;
  }
}
.heading-04 {
  font-size: 25px;
  line-height: 30px;
  @extend .butler-300;
  @media only screen and (max-width: 1700px) {
    font-size: 18px;
    line-height: 22px;
  }
  @media only screen and (max-width: 1200px) {
    font-size: 17px;
    line-height: 20px;
  }
  @media only screen and (max-width: 767px) {
    // font-size: 14px;
  }
}
.heading-wrp-02 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  // margin: 2vw 0px;
  &.is-white {
    color: #fff;
  }
  &.is-gray {
    color: $dark-gray;
  }
}
:root {
  --width-01: 550px;
}

.heading-wrp {
  width: 100%;
  display: flex;
  flex-direction: column;
  &.half-width {
    width: 50%;
  }
  &.big-width {
    width: 45vw;
    @media screen and (min-width: 1710px) {
      margin-left: 36px;
    }
    @media only screen and (max-width: 1440px) {
      // max-width: 630px;
      // margin-right: 5px;
    }
  }
  &.small-width {
    width: 38%;
    @media only screen and (max-width: 1700px) {
      // max-width: 356px;
      max-width: 500px;
    }
    @media only screen and (max-width: 1440px) {
      max-width: 460px;
      margin-right: 5px;
    }
    @media only screen and (max-width: 1140px) {
      max-width: 650px;
    }
  }
  &.text-center {
    align-items: center;
    margin: auto;
  }
  &.text-left {
    align-items: start;
  }
  &.bottom-width {
    margin: 0px auto 4vw auto;
  }
  @media only screen and (max-width: 1700px) {
    &.medium-width {
      max-width: 435px;
      margin-right: 10px;
    }
  }
  @media only screen and (max-width: 1023px) {
    &.small-width {
      width: 70%;
    }
  }
  @media only screen and (max-width: 767px) {
    &.small-width {
      width: 100%;
    }
  }
  @media only screen and (max-width: 700px) {
    &.big-width {
      margin-top: 40px;
      width: 90%;
    }
  }
}

.heading-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

// paragraph
.is-para {
  font-size: 20px;
  &.para-small {
    font-size: 18px;
  }
  &.para-big {
    font-size: 30px;
  }
  &.para-medium {
    font-size: 26px;
  }
  &.para-min {
    font-size: 14px;
  }
  &.para-center {
    text-align: center;
  }
  &.small-width {
    width: 100%;
    max-width: 500px;
  }
  &.is-gray {
    color: #6f6f6f;
  }
  &.is-black {
    color: $black;
  }
  &.para-white {
    color: $white !important;
  }
  &.is-bold {
    font-weight: 600;
  }
  @media only screen and (max-width: 1700px) {
    font-size: 16px;
    &.para-small {
      font-size: 14px;
    }
    &.para-big {
      font-size: 24px;
    }
    &.para-medium {
      font-size: 20px;
    }
    &.para-min {
      font-size: 12px;
    }
  }
  @media only screen and (max-width: 1200px) {
    font-size: 14px;
    &.para-small {
      font-size: 12px;
    }
    &.para-big {
      font-size: 22px;
    }
    &.para-medium {
      font-size: 18px;
    }
    &.para-min {
      font-size: 11px;
    }
  }
}

//img-wrp
.img-wrp {
  width: 100%;
  img {
    width: 100%;
    display: block;
  }
  .img-auto {
    width: auto !important;
  }
}

.two-img-wrp {
  position: relative;
  margin-bottom: 11vw;
  .img-01 {
    width: 79.5%;
  }
  .img-02 {
    width: 73.7%;
    position: absolute;
    right: 0;
    bottom: -11vw;
  }
}
.two-img-wrp-02 {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  // &:not(:last-child){
  padding-bottom: 5%;
  // }
  .img-01 {
    width: 69%;
  }
  .img-02 {
    width: 26.1%;
    margin: 0 0 0 5%;
  }
  &.swipe {
    flex-direction: row-reverse;
    .img-02 {
      margin: 0 5% 0 0;
    }
  }
  @media only screen and (max-width: 767px) {
    flex-wrap: wrap;
    .img-01 {
      width: 100%;
    }
    .img-02 {
      width: 100%;
      margin: 20px 0;
    }
    &.swipe {
      .img-02 {
        margin: 20px 0;
      }
    }
  }
}
.two-img-wrp-03 {
  position: relative;
  .img-01 {
    width: 78.7%;
  }
  .img-02 {
    position: absolute;
    right: 2%;
    top: -58%;
    width: 31.4%;
  }
  @media only screen and (max-width: 1023px) {
    .img-01 {
      width: 80%;
      margin: auto;
    }
    .img-02 {
      display: none;
    }
  }
  @media only screen and (max-width: 767px) {
    .img-01 {
      width: 90%;
    }
  }
}
.icon-and-two-img-wrp-03 {
  .content-wrp {
    width: 54%;
    .list-wrp {
      margin: 0 0;
      .column {
        padding: 0 7%;
        padding: 0 4%;
      }
    }
  }
  @media only screen and (max-width: 1023px) {
    .content-wrp {
      width: 80%;
      margin: 0 auto;
      .list-wrp {
        display: flex;
        .column {
          padding: 0 4%;
          width: 33.333%;
          flex-basis: auto;
        }
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .content-wrp {
      width: 90%;
      .heading-01 {
        text-align: center;
      }
      .list-wrp {
        .column {
          width: 50%;
          padding: 0 5px 10px;
        }
      }
    }
  }
}
.three-img-wrp {
  position: relative;
  padding-bottom: 22vw;
  .container {
    > div {
      display: flex;
      justify-content: flex-start;
    }
  }
  .img-wrp {
    width: 36%;
  }
  .img-02 {
    width: 22%;
    position: absolute;
    right: 29%;
    bottom: -16vw;
  }
  .img-03 {
    position: absolute;
    width: 16%;
    right: 0;
    top: 54%;
  }
  .content-wrp {
    width: 39%;
    margin-left: 7%;
    margin-top: 5%;
    .is-para {
      padding-right: 10%;
    }
  }
  @media only screen and (max-width: 1023px) {
    .container {
      > div {
        flex-direction: column-reverse;
        width: 90%;
        margin: auto;
      }
    }
    .img-wrp {
      position: relative;
      width: 100%;
      .img-01 {
        width: 60%;
      }
      .img-02 {
        right: inherit;
        left: calc(60% + 10px);
        bottom: 0;
        width: 37.5%;
      }
      .img-03 {
        right: inherit;
        left: calc(60% + 10px);
        top: 0;
        width: 37.5%;
      }
    }
    .content-wrp {
      width: 100%;
      margin: 0 auto 20px;
      .heading-01 {
        text-align: center;
      }
      .is-para {
        text-align: center;
        padding: 0 20px;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .img-wrp {
      position: relative;
      width: 100%;
      .img-01 {
        width: 61%;
      }
    }
    .content-wrp {
      .is-para {
        padding: 0;
      }
    }
  }
}
.three-img-wrp-02 {
  position: relative;
  height: 90vh;
  width: 63%;
  img {
    position: absolute;
    z-index: 2;
  }
  .img-01 {
    width: 48.3% !important;
    left: 0;
    top: 0;
  }
  .img-02 {
    left: 21%;
    top: 39.5%;
    width: 54.4% !important;
    z-index: 1;
  }
  .img-03 {
    left: 53.5%;
    top: 13.5%;
    width: 47.6% !important;
  }
  .horizontal-center {
    bottom: 9%;
    z-index: 2;
  }
}

// col-50-50-wrp
.col-50-50-wrp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div {
    width: 50%;
  }
  .content-wrp {
    padding-left: 8%;
    padding-right: 0;
  }
  &.small-img {
    .img-wrp {
      width: 38.4%;
    }
    .content-wrp {
      padding-left: 4%;
      padding-right: 0;
    }
  }
  &.swipe {
    flex-direction: row-reverse;
    .content-wrp {
      padding-left: 0%;
      padding-right: 8%;
    }
    &.small-img {
      .content-wrp {
        padding-left: 0%;
        padding-right: 4%;
      }
    }
  }
  @media only screen and (max-width: 1023px) {
    flex-wrap: wrap;
    justify-content: center;
    > div {
      width: 70%;
    }
  }
  @media only screen and (max-width: 767px) {
    > div {
      width: 90%;
    }
    .content-wrp {
      text-align: center;
      padding-left: 0;
    }
    .heading-01 {
      text-align: center;
    }
    &.swipe {
      .content-wrp {
        padding-right: 0;
      }
      &.small-img {
        .content-wrp {
          padding-right: 0;
        }
      }
    }
  }
}

// col-50-50-wrp
.col-50-50-wrp-02 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div {
    width: 50%;
  }
  .content-wrp {
    padding-left: 6%;
    padding-right: 0;
    p {
      padding-right: 8%;
    }
  }
  &.from-top {
    align-items: flex-start;
    .heading-01 {
      transform: translateX(-24%);
    }
  }
  &.swipe {
    flex-direction: row-reverse;
    .content-wrp {
      padding-left: 0%;
      padding-right: 6%;
    }
  }
  @media only screen and (max-width: 1023px) {
    flex-wrap: wrap;
    justify-content: center;
    > div {
      width: 70%;
    }
    .content-wrp {
      padding-top: 8vw;
      padding-left: 12%;
    }
    &.from-top {
      .heading-01 {
        transform: translateX(-14%);
      }
    }
  }
  @media only screen and (max-width: 767px) {
    flex-direction: column-reverse;
    > div {
      width: 90%;
    }
    .content-wrp {
      padding: 20px 0 8vw;
      text-align: center;
      p {
        padding-right: 0;
      }
    }
    .heading-01 {
      text-align: center;
    }
    &.from-top {
      align-items: center;
      .heading-01 {
        transform: translateX(0%);
      }
    }
    &.swipe {
      flex-direction: column-reverse;
      .content-wrp {
        padding: 0 0 8vw;
      }
    }
  }
}

// col-70-40-wrp
.col-70-40-wrp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .img-wrp {
    width: 57.5%;
  }
  .content-wrp {
    width: 42.5%;
    padding-left: 7%;
    padding-right: 0;
    &.padding {
      padding-left: 12%;
      padding-right: 0;
    }
  }
  &.swipe {
    flex-direction: row-reverse;
    .content-wrp {
      padding-left: 0%;
      padding-right: 7%;
      &.padding {
        padding-left: 0%;
        padding-right: 12%;
      }
    }
  }
  @media only screen and (max-width: 1023px) {
    flex-wrap: wrap;
    justify-content: center;
    .img-wrp {
      width: 70%;
    }
    .content-wrp {
      width: 60%;
      padding: 30px 0 0;
      &.padding {
        padding-left: 0;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .img-wrp {
      width: 90%;
    }
    .content-wrp {
      width: 84%;
      padding: 20px 0 0;
      &.padding {
        padding-left: 0;
      }
    }
    .heading-01,
    .is-para {
      text-align: center;
    }
    &.swipe {
      flex-direction: column-reverse;
      .content-wrp {
        // padding: 20px 0 0;
        padding: 0 0 20px;
      }
    }
  }
}

// col-50-50-wrp
.col-30-70-wrp {
  display: flex;
  align-items: center;
  padding-bottom: 7%;
  .img-wrp {
    width: 50%;
    margin: 0 8% 0 8%;
    position: relative;
  }
  .content-wrp {
    width: 35%;
    margin-right: auto;
  }
  .three-img-wrp-02 {
    height: 88vh;
    width: 63%;
    margin: 0 8% 0 0;
  }
  &.swipe {
    flex-direction: row-reverse;
    .img-wrp {
      margin: 0 8% 0 7%;
    }
    .three-img-wrp-02 {
      margin: 0 0 0 8%;
    }
  }
  @media only screen and (max-width: 1700px) {
    .three-img-wrp-02 {
      height: 85vh;
    }
  }
  @media only screen and (max-width: 1200px) {
    .three-img-wrp-02 {
      height: 458px;
    }
  }
  @media only screen and (max-width: 1023px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    .img-wrp {
      width: 70%;
      margin: 0 7% 0 0%;
    }
    .content-wrp {
      width: 70%;
      margin: 0 10% 20px 0%;
    }
    .three-img-wrp-02 {
      height: 50.5vw;
      width: 63%;
      margin: 0 18% 0 0;
    }
    &.swipe {
      flex-direction: column-reverse;
      .img-wrp {
        margin: 0 0% 0 7%;
      }
      .content-wrp {
        margin: 0 0 20px 10%;
        .heading-01,
        .is-para {
          text-align: right;
        }
      }
      .three-img-wrp-02 {
        margin: 0 0 0 18%;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    padding-bottom: 120px;
    position: relative;
    .heading-01 {
      margin-top: 30px;
      text-align: center;
    }
    .content-wrp {
      width: 90%;
      margin: 0 20px 0 5%;
      text-align: center;
    }
    .three-img-wrp-02 {
      width: 84%;
      height: auto;
      margin: 0;
      position: static;
      img {
        position: relative;
      }
      .img-01 {
        width: 100% !important;
      }
      .img-02,
      .img-03 {
        display: none;
      }
    }
    &.swipe {
      flex-direction: column;
      .content-wrp {
        margin: 0 20px 0 5%;
        .heading-01,
        .is-para {
          text-align: center;
        }
      }
      .three-img-wrp-02 {
        margin: 0;
      }
    }
  }
}

// col-90-10-wrp
.col-90-10-wrp {
  display: flex;
  justify-content: flex-end;
  position: relative;
  .item-wrp {
    padding-bottom: 6vw;
  }
  .img-wrp {
    width: 83.5%;
    @extend .is-bottom-gradient;
    @extend .is-light-blue-grd;
    position: relative;
  }
  .content-wrp {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
  }
  .btn-big-cricle-wrp {
    z-index: 2;
    &.horizontal-center {
      left: 50%;
    }
  }
  &.swipe {
    justify-content: flex-start;
    .content-wrp {
      left: 73%;
    }
    .btn-cricle-wrp {
      &.horizontal-center {
        left: 42.5%;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    padding-bottom: 70px;
    .img-wrp {
      width: 100%;
      position: static;
      margin: 40px 0 20px;
    }
    .content-wrp {
      position: static;
      transform: translateY(0);
      text-align: center;
      .heading-01 {
        text-align: center;
        br {
          display: none;
        }
      }
    }
  }
}

//row-reverse
.row-reverse-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5vw;
  .content-wrp {
    width: 58%;
    padding-left: 12%;
    padding-right: 11%;
  }
  .img-wrp {
    width: 42%;
  }
  &.swipe-row-reverse {
    flex-direction: row-reverse;
    .content-wrp {
      padding-right: 10%;
      padding-left: 0%;
    }
  }
  @media screen and (max-width: 1700px) {
    margin-bottom: 3vw;
  }
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    .img-wrp {
      width: 100%;
    }
    .content-wrp {
      width: 100%;
      padding: 15px 20px 10px;
    }
  }
}

//two-img-section
.two-img-section {
  overflow: hidden;
  .two-img-slider {
    width: 74.5%;
    .owl-item {
      transform: scale(0.75);
      @extend .transition-all-03s-ease;
      &.active {
        transform: scale(1);
      }
    }
  }
  .owl-carousel {
    .owl-stage-outer {
      overflow: visible;
    }
  }
  .slider-btn-circle {
    &.owl-theme {
      .owl-nav {
        .owl-prev {
          right: -9.5%;
          left: inherit;
          top: 45%;
        }
        .owl-next {
          right: -9.5%;
          top: 55%;
        }
      }
    }
  }
}

// input number
/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

//accordion
.tab-accordion {
  overflow: hidden;
  transition: height 0.3s ease-out;
  &.active {
    height: auto;
    transition: height 0.3s ease-in;
  }
  .accordion-section-content {
    display: none;
    // animation
    /*
    max-height: 0; // Start with 0 height
    overflow: hidden; // Hide any overflowing content
    transition: max-height 10.3s ease-out; // Add transition effect
    */
    &.open {
      display: block;
      // max-height: 1000px;
      // transition: max-height 10.3s ease-in; // Add transition effect
    }
  }
}

.faqs {
  padding: 80px 0;
}
.tab-accordion {
  padding: 20px 30px;
  background: #f5f8ff;
  border-radius: 10px;
  margin-bottom: 40px;
  .accordion-section-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    .is-para{
      padding-left: 10px;
    }
    &:after {
      content: "";
      display: none;
    }
    span {
      width: 40px;
      height: 20px;
      position: relative;
      right: 0;
      display: inline-flex;
      &:after {
        content: "+";
        font-size: 50px;
        position: absolute;
        right: 25px;
        top: 50%;
        transform: translateY(-50%);
        color: #979797;
      }
    }
    &.active {
      p {
        // color: $blue;
        pointer-events: none;
      }
      span {
        pointer-events: none;
        &:after {
          content: "-";
          right: 60%;
        }
      }
    }
  }
  // .accordion-section-content {
  //   @extend .padding-t-4;
  // }
  @media screen and (max-width: 1700px) {
    padding: 1vw 0;
    .accordion-section-title {
      span {
        &:after {
          font-size: 30px;
        }
      }
    }
  }
  @media screen and (max-width: 800px) {
    .accordion-section-title {
      .is-para {
        padding: 10px 10px;
      }
    }
    .accordion-section-content {
      .is-para {
        padding: 0 10px;
      }
    }
  }
  @media screen and (max-width: 450px) {
    .accordion-section-title {
      .is-para {
        padding-right: 27px;
      }
    }
  }
}
// .link-accordion {
//   .accordion-section-title {
//     &:after {
//       content: "";
//       display: none;
//     }
//     span {
//       width: 40px;
//       height: 20px;
//       position: relative;
//       right: 0;
//       display: inline-flex;
//       &:after {
//         content: "+";
//         font-size: 18px;
//         position: absolute;
//         right: 15px;
//         top: 56%;
//         -webkit-transform: translateY(-50%);
//         transform: translateY(-50%);
//       }
//     }
//     &.active {
//       span {
//         &:after {
//           content: "-";
//         }
//       }
//     }
//   }
// }

//icon-wrp
.icon-wrp {
  display: flex;
  align-items: center;
  img,
  svg {
    margin-right: 10px;
    margin-top: 4px;
  }
  @media screen and (max-width: 1200px) {
    img,
    svg {
      width: 12px;
    }
  }
}

// List
// .list-wrp-parent {
//   &.img-list-parent {
//     margin-top: 12%;
//     .column {
//       padding: 1.6%;
//       &:not(:nth-child(3n - 1)) {
//         // margin-top: -7%;
//       }
//     }
//   }
//   &.product-list-parent {
//     .column {
//       padding-left: 4%;
//       &:nth-child(2n - 1) {
//         padding-left: 0;
//         padding-right: 4%;
//       }
//       &:nth-last-child(n + 3) {
//         // margin-bottom: 14%;
//       }
//     }
//   }
//   &.product-list-02-parent {
//     margin-top: 7%;
//     .column {
//       padding: 0 7%;
//       &:nth-child(2n - 1) {
//         margin-top: -7%;
//       }
//       &:nth-last-child(n + 3) {
//         margin-bottom: 14%;
//       }
//     }
//   }
//   &.item-slider-01 {
//     .owl-item {
//       .column {
//         padding: 0 12%;
//       }
//       &:nth-child(2n - 1) {
//         .column {
//           margin-top: 12%;
//         }
//       }
//     }
//   }
//   @media screen and (min-width: 1024px) {
//     &.img-list-parent {
//       .column {
//         &:not(:nth-child(3n - 1)) {
//           margin-top: -7%;
//         }
//       }
//     }
//   }
//   @media screen and (max-width: 1023px) {
//     display: flex;
//     &.img-list-parent {
//       .column {
//         width: 50%;
//         flex-basis: auto;
//         margin-top: -50px;
//         flex-grow: inherit;
//         &:not(:nth-child(2n - 1)) {
//           margin-top: 0;
//         }
//       }
//     }
//     &.product-list-02-parent {
//       margin-top: 4%;
//       .column {
//         padding: 0 4%;
//         &:nth-child(2n - 1) {
//           margin-top: -4%;
//         }
//         &:nth-last-child(n + 3) {
//           margin-bottom: 14%;
//         }
//       }
//     }
//   }
//   @media screen and (max-width: 767px) {
//     &.img-list-parent {
//       margin-top: 70px;
//       .column {
//         width: 50%;
//         flex-basis: auto;
//         margin-top: -50px;
//         flex-grow: inherit;
//         &:not(:nth-child(2n - 1)) {
//           margin-top: 0;
//         }
//       }
//     }
//     &.product-list-02-parent {
//       margin-top: 0%;
//       display: flex;
//       .column {
//         padding: 0 0%;
//         width: 80%;
//         flex-basis: auto;
//         flex-grow: inherit;
//         margin: 0 auto 30px;
//         &:nth-child(2n - 1) {
//           margin-top: 0%;
//         }
//       }
//     }
//   }
// }
// .list-wrp {
//   &.margin-right {
//     .heading-wrp {
//       margin-right: 20%;
//     }
//     .img-wrp {
//       width: 70px;
//     }
//   }
//   &.tick {
//     span {
//       margin-left: 15px;
//     }
//     p {
//       &:not(:last-child) {
//         margin-bottom: 15px;
//       }
//     }
//   }
//   &.disc {
//     span {
//       display: block;
//       margin-left: 15px;
//     }
//     p {
//       position: relative;
//       &:before {
//         content: "";
//         background-color: $dark-gray;
//         width: 5px;
//         height: 5px;
//         border-radius: 50%;
//         position: absolute;
//         left: 0;
//         top: 12px;
//       }
//       &:not(:last-child) {
//         margin-bottom: 5px;
//       }
//     }
//   }
//   &.img-list {
//     position: relative;
//     .img-wrp {
//       img {
//         display: block;
//       }
//       &.overlay {
//         &:before {
//           content: "";
//           position: absolute;
//           left: 0;
//           bottom: 0;
//           width: 100%;
//           height: 100%;
//           background: linear-gradient(
//             0deg,
//             #141e30 -0.05%,
//             rgba(20, 30, 48, 0) 40.62%
//           );
//         }
//       }
//     }
//     .content-wrp {
//       position: absolute;
//       left: 0;
//       bottom: 0;
//       width: 100%;
//       height: 100%;
//       display: flex;
//       flex-direction: column;
//       justify-content: flex-end;
//       // padding: 14% 16% 14% 12%;
//       padding: 8% 10% 8% 8%;
//       text-align: center;
//     }
//   }
//   &.product-list {
//     @extend .transition-all-03s-ease;
//     padding-bottom: 4vw;
//     .full-img {
//       width: 100%;
//     }
//     .heading-02 {
//       padding: 2.4vw 0px 1.4vw;
//     }
//     &:hover {
//       // background-color: $lightest-gray;
//       // box-shadow: 0 0 10px 0 #0000001a;
//     }
//   }
//   &.product-list-02 {
//     @extend .transition-all-03s-ease;
//     .full-img {
//       width: 100%;
//     }
//     &:hover {
//       // background-color: $lightest-gray;
//       // box-shadow: 0 0 10px 0 #0000001a;
//     }
//   }
//   &.two-columns {
//     > .column {
//       padding-bottom: 3vw;
//       padding-right: 1.5vw;
//       &:nth-child(2n) {
//         padding-left: 1.5vw;
//         padding-right: 0;
//       }
//     }
//   }
//   &.three-columns {
//     .img-wrp {
//       width: 60px;
//       &.center {
//         margin: 0 auto 20px;
//       }
//     }
//     > .column {
//       &:not(:nth-last-child(-n + 3)) {
//         padding-bottom: 3vw;
//       }
//       &:nth-child(3n - 1) {
//         padding-right: 1.5vw;
//         padding-left: 1.5vw;
//       }
//     }
//   }
//   @media screen and (max-width: 1700px) {
//     &.margin-right {
//       .heading-wrp {
//         margin-right: 12%;
//       }
//       .img-wrp {
//         width: 55px;
//       }
//     }
//     &.img-list {
//       .content-wrp {
//         padding: 6% 8% 6% 6%;
//       }
//     }
//     &.three-columns {
//       .img-wrp {
//         width: 45px;
//       }
//     }
//   }
//   @media screen and (max-width: 1200px) {
//     &.product-list {
//       padding: 6%;
//     }
//   }
//   @media screen and (max-width: 1023px) {
//     &.margin-right {
//       // width: 50%;
//       flex-basis: auto;
//     }
//   }
//   @media screen and (max-width: 767px) {
//     &.margin-right {
//       width: 100%;
//       .heading-wrp {
//         margin-right: 0;
//       }
//     }
//     &.product-list {
//       padding: 20px 20px 30px;
//     }
//   }
// }

//Box list
// .list-padding-right {
//   .heading-wrp {
//     margin-right: 10%;
//     padding-right: 20%;
//   }
// }

// //Video
// .video-item {
//   position: relative;
//   display: block;
//   .play-btn {
//     position: absolute;
//     left: 0;
//     right: 0;
//     top: 0;
//     bottom: 0;
//     margin: auto;
//     display: inline-flex;
//     align-items: center;
//     justify-content: center;
//     width: 60px;
//   }
//   @media only screen and (max-width: 1700px) {
//     .play-btn {
//       width: 44px;
//     }
//   }
// }

// // Download
// .downloads-list {
//   .item {
//     background-color: #fff;
//     display: inline-flex;
//     width: 22%;
//     justify-content: space-between;
//     align-items: center;
//     padding: 10px 14px;
//     margin-bottom: 20px;
//     border: 1px solid #d3d3d3;
//     @extend .transition-all-03s-ease;
//     p {
//       width: calc(100% - 40px);
//     }
//     span {
//       color: #adadad;
//     }
//     &:not(:nth-child(4n)) {
//       margin-right: 4%;
//     }
//     &:hover {
//       box-shadow: 0 0 5px 0 #0000001a;
//     }
//   }
//   @media only screen and (max-width: 1700px) {
//     .item {
//       width: 22.7%;
//       margin-bottom: 14px;
//       &:not(:nth-child(4n)) {
//         margin-right: 3%;
//       }
//       svg {
//         width: 20px;
//       }
//     }
//   }
//   @media only screen and (max-width: 768px) {
//     display: flex;
//     flex-wrap: wrap;
//     justify-content: space-between;
//     .item {
//       width: 49%;
//       margin-bottom: 10px;
//       padding: 7px 10px;
//       &:not(:nth-child(4n)) {
//         margin-right: 0%;
//       }
//       svg {
//         width: 16px;
//       }
//     }
//   }
// }

// //image-zoom
// .image-zoom {
//   overflow: hidden;
//   @extend .transition-all-03s-ease;
//   .img {
//     display: block;
//     @extend .transition-all-03s-ease;
//   }
//   &:hover {
//     // transform: scale(0.95);
//     .img {
//       transform: scale(1.1);
//     }
//     &*:not(img) {
//       // transform: scale(1.03);
//     }
//   }
// }

// //popup-up
// .popup-up {
//   display: none;
//   position: fixed;
//   left: 0;
//   top: 0;
//   width: 100%;
//   height: 100%;
//   z-index: 99;
//   align-items: center;
//   justify-content: center;
//   background-color: rgba(0, 0, 0, 0.85);
//   &.active {
//     display: flex;
//   }
//   .container {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     > div {
//       position: relative;
//       width: 50%;
//       padding: 20px 30px 30px;
//     }
//   }
//   .column {
//     padding-top: 0.5rem;
//     padding-bottom: 0.5rem;
//   }
//   .label:not(:last-child) {
//     font-size: 12px;
//     margin-bottom: 0.1em;
//   }
//   .btn-big-cricle-wrp {
//     margin-left: auto;
//   }
//   .close-btn {
//     display: flex;
//     position: absolute;
//     right: 15px;
//     font-size: 28px;
//     top: 10px;
//     color: #fff;
//     transform: rotate(45deg);
//     background: #000;
//     width: 30px;
//     height: 30px;
//     border-radius: 50%;
//     text-align: center;
//     align-items: center;
//     justify-content: center;
//     border: 1px solid #ffff;
//     cursor: pointer;
//     padding: 14px 15px 16px 15px;
//   }
//   @media only screen and (max-width: 768px) {
//     .container {
//       > div {
//         width: 100%;
//         padding: 20px 30px 30px;
//       }
//     }
//     .form-row {
//       display: block;
//     }
//   }
// }

//connect-with-us
.connect-with-us {
  @media screen and (max-width: 768px) {
    .is-para {
      text-align: left;
    }
    .container {
      > .columns {
        > .column {
          &:last-child {
            margin-top: 12px;
            > .columns {
              padding: 20px 30px !important;
            }
            .heading-02 {
              text-align: left !important;
            }
          }
        }
      }
    }
  }
}
// .get-in-touch {
//   .form-row {
//     display: block;
//   }
// }

//text-counter
// .text-counter {
//   font-size: 80px;
//   @extend .butler-300;
//   display: flex;
//   flex-direction: column;
//   span {
//     &:last-child {
//       font-size: 60px;
//     }
//   }
//   @media screen and (max-width: 1700px) {
//     font-size: 50px;
//     span {
//       &:last-child {
//         font-size: 32px;
//       }
//     }
//   }
//   @media screen and (max-width: 1200px) {
//     font-size: 34px;
//     span {
//       &:last-child {
//         font-size: 18px;
//       }
//     }
//   }
// }

// header
// transparent navbar
// img.logo-white {
//   // display: none;
//   width: 150px;
//   max-height: none;
//   @media screen and (max-width: 1700px) {
//     width: 100px;
//   }
// }
// img.logo-green {
//   display: block;
//   width: 150px;
//   max-height: none;
//   @media screen and (max-width: 1023px) {
//     width: 100px;
//   }
// }

.is-transparent {
  background-color: transparent;
  box-shadow: none !important;
  // .logo-white{
  //     display: block;
  // }
  // .logo-green{
  //     display: none;
  // }
  // .navbar-menu{
  .navbar-link,
  .navbar-item {
    // color: white;
    &.header-call-btn {
      img {
        filter: invert(100%) sepia(0%) saturate(7452%) hue-rotate(295deg)
          brightness(98%) contrast(96%);
      }
    }
    &:hover {
      // color: $blue;
      color: $blue !important;
    }
  }
  .navbar-item {
    .navbar-link:not(.is-arrowless)::after {
      border-color: white;
    }
    svg {
      path {
        fill: white;
      }
    }
  }
  // }
  .navbar-burger {
    span {
      background-color: $white !important;
    }
    &.is-active {
      span {
        background-color: $black !important;
      }
    }
  }
}

// auth
.login-wrp-section {
  .is-vcentered {
    .heading-01 {
      text-align: center;
    }
    label {
      font-weight: 400;
    }
    .column {
      .is-8 {
        .field {
          .select {
            width: 100%;
            border: 1px solid $light-gray2;
            border-radius: 3px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .is-vcentered {
      &.reset-mobile,
      .login-content {
        width: 100%;
        margin: auto;
      }
    }
    .column {
      .is-12 {
        width: 60%;
        margin: auto;
      }
    }
  }
}

// checkout
.checkout-section {
  font-family: "Roboto Flex", sans-serif !important;
  @media screen and (max-width: 768px) {
    .container {
      .left-bar-tab {
        display: none;
      }
    }
  }
}

// Width
.element-width {
  width: auto;
  &.width-full {
    width: 100%;
  }
}

// border
.is-full-border {
  border: 1px solid $gray;
}

// Account
.account-section {
  font-family: "Roboto Flex", sans-serif !important;
  .container {
    .orderSection {
      border: 1px solid #ebfffc;
      .columns {
        hr {
          width: 100%;
        }
      }
      .order-product-img-wrap {
        width: 7.6vw;
      }
    }
    .service-form-card {
      border: 1px solid rgba(238, 249, 255, 1);
      margin: 30px 0;
    }
    .service-head-section {
      padding: 30px;
    }
    .title {
      .is-5 {
        font-weight: 600;
      }
    }
    .toggle-btn-01 {
      display: flex;
      justify-content: center;
      width: 100%;
      div {
        // text-decoration-line: underline;
        .is-para {
          color: $gray2;
          padding: 20px;
          &.active {
            color: $blue;
            border-bottom: 1px solid $blue;
          }
        }
      }
    }
    .Ongoing,
    .Completed {
      padding: 0;
      .sub-bought-card {
        padding: 0;
        margin: 0;
        border: 1px solid #eef9ff;
        margin: 1vw 0;
        .is-align-items-center {
          margin: 0;
        }
        .is-justify-content-space-between {
          background-color: #f5f5f5;
        }
      }
    }
    .input-date,
    .form-control {
      width: 100%;
      border: 1px solid #e7e7e7;
    }
    .my-0 {
      width: 98%;
      margin: auto;
      color: #dbdbdb;
    }

    @media only screen and (max-width: 820px) {
      width: 100%;
      .main-account-sec-wrap {
        display: flex;
        flex-direction: column;
        .account-sec-details {
          margin-top: 40px;
          width: 100%;
        }
        .orderSection {
          .order-product-img-wrap {
            width: 15vw;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 500px) {
    margin: 0 !important;
    .service-form {
      display: block !important;
    }
    .pr-6 {
      padding-right: 0 !important;
    }
    .orderCardWrap {
      .columns {
        display: block !important;
      }
    }
    .main-account-sec-wrap {
      .orderSection {
        .product-card-details {
          .is-flex {
            padding: 0 !important;
          }
          .order-product-img-wrap {
            width: 80%;
            margin: auto;
          }
        }
      }
    }
  }
}

// About us

.banner-img-wrap-02 {
  width: 40% !important;
  img {
    border-radius: 11px !important;
  }
  @media only screen and (max-width: 500px) {
    width: 80% !important;
  }
}
@media only screen and (max-width: 769px) {
  .our-vision {
    flex-direction: column;
    .heading-wrp {
      text-align: center;
      h2 {
        margin: auto;
      }
    }
  }
  .about-sec,.our-vision,.our-mission{
    .heading-wrp{
      width: 80% !important;
    }
  } 
}

// thank you
.thank-you-page {
  .container {
    .columns {
      .column {
        display: flex;
        flex-direction: column;
        align-items: center;
        .img-wrap-02 {
          width: 24vw;
        }
        * {
          margin: 18px 0px;
        }
        .is-4 {
          margin: 0;
        }
        @media only screen and (max-width: 500px) {
          .img-wrap-02 {
            width: 60vw;
          }
          .title {
            &.is-2 {
              font-size: 1.5rem;
              font-weight: 700;
            }
            &.is-5 {
              font-size: 1.1rem;
            }
          }
        }
      }
    }
  }
}

// product
.filter-section {
  display: flex;
  .filter-section-side {
    border: 1px solid $gray;
    width: 15%;
    height: fit-content;
    .para-medium.is-black2 {
      margin: 10px 0;
    }
    .para-medium.is-blue {
      margin-bottom: 10px;
    }
    .para-medium.is-black2,
    .brandFillterSec {
      margin-left: 20px;
    }
    .big-line {
      width: 80%;
      margin-left: 20px;
      margin-right: 20px;
    }
    .brandFillterSec {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .fillterCont {
      margin-bottom: 10px;
    }
    label {
      margin-left: 10px;
    }
    .full-line {
      margin: 0;
    }
    @media only screen and (max-width: 820px) {
      width: 25%;
    }
  }
  .productListing {
    width: 100%;
    .container {
      .card-01 {
        box-shadow: none;
        margin: 0;
        padding: 0;
        .card-wrap {
          display: flex;
          flex-wrap: wrap;
          .Card-container {
            width: 30%;
            padding: 20px;
            margin: 25px auto;
            box-shadow: 13px 34px 84px rgba(142, 142, 142, 0.2);
            * {
              margin: 15px 0;
            }
            .productCardImg {
              margin: 0;
            }
          }
        }
      }
      @media only screen and (max-width: 820px) {
        .card-02 {
          .card-wrap {
            .Card-container {
              width: 45%;
            }
          }
        }
      }
      @media only screen and (max-width: 600px) {
        .card-02 {
          .card-wrap {
            .Card-container {
              width: 80%;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 500px) {
    flex-direction: column;
    .filter-section-side {
      width: 100%;
    }
  }
}

// Product details
.homePage_banner {
  .homeMainBanner {
    .container {
      .banner-img-wrap {
        width: 38vw;
      }
      .half-width {
        width: 45%;
      }
      @media only screen and (max-width: 820px) {
        flex-direction: column-reverse;
        .banner-img-wrap {
          width: 70vw;
        }
        .half-width {
          margin-top: 4vw;
          width: 70%;
        }
      }
    }
  }
}
.product-discription {
  .container {
    .product-details-menu-wrap {
      position: sticky;
      top: 0;
      width: 100%;
      background-color: $white;
      z-index: 2;
      .product-details-menu {
        width: 40%;
        margin: auto;
        display: flex;
        justify-content: space-evenly;
        .is-para {
          padding: 25px 0;
          &.active {
            color: $blue;
            border-bottom: 1px solid $blue;
          }
        }
      }
    }
    hr {
      margin-top: 0;
      color: $gray3;
    }
    .prod-desc-section {
      // opacity: 0;
      transition: opacity 0.3s ease-in-out;
      &.active {
        // opacity: 1;
      }
      .homeMainBanner {
        .container {
          width: unset;
          .img-wrp {
            width: 37vw;
          }
          .heading-wrp {
            ul {
              li {
                list-style-type: disc;
                margin: 10px 0;
              }
            }
          }
        }
      }
    }
    @media only screen and (max-width: 820px) {
      .prod-desc-section {
        .homeMainBanner {
          .container {
            &.specifications {
              flex-direction: column-reverse;
            }
            .half-width {
              width: 85%;
            }
            .img-wrp {
              width: 70vw;
            }
          }
        }
      }
      .product-details-menu-wrap {
        .product-details-menu {
          width: 80%;
          .is-para {
            padding: 25px 20px;
          }
        }
      }
    }
  }
}

// Maps
.google-map {
  height: 30vw;
  width: 40vw;
  @media only screen and (max-width: 768px) {
    height: 50vw;
    width: 78vw;
  }
}

// Our Service
.our-service {
  .area-services-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .area-service-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 22%;
      margin: 22px;
      .is-para {
        &.is-700 {
          margin: 18px 0;
        }
        &.is-gray {
          margin-bottom: 18px;
        }
      }
    }
  }
  .description {
    margin: 0 40px;
  }
  @media only screen and (max-width: 1440px) {
    .area-services-box {
      .area-service-card {
        width: 20%;
      }
    }
  }
  @media only screen and (max-width: 820px) {
    .area-services-box {
      .area-service-card {
        width: 40%;
        .is-para {
          &.is-700 {
            margin: 13px 0;
          }
          &.is-gray {
            margin-bottom: 13px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 515px) {
    .area-services-box {
      .area-service-card {
        width: 80%;
      }
    }
  }
}

// shopping cart
.shopping-card-total {
  margin: 0 auto;
  .shopping-card-total-wrap {
    margin: auto;
    padding: 20px;
    border: 1px solid #f5f5f5;
    .shopping-card-total-container {
      width: 85%;
      margin: auto;
      .columns {
        padding: 12px 0;
      }
    }
  }
  .shopping-card-assistance {
    margin-top: 20px;
    h4 {
      margin-bottom: 10px;
    }
    span {
      color: $black;
      margin-right: 10px;
    }
  }
}
@media only screen and (max-width: 820px) {
  .shopping-cart-sec-container {
    display: flex;
    flex-direction: column;
    .shopping-cart-products {
      width: 100%;
    }
    .shopping-card-total {
      width: 50%;
    }
  }
}

// blogs
.ReadBlogs {
  .card-02 {
    .card-wrap {
      display: flex;
      flex-wrap: wrap;
      .Card-container {
        width: 29%;
        margin: 30px auto;
        box-shadow: 13px 34px 84px rgba(142, 142, 142, 0.2);
        .card-discrip-warp {
          padding: 20px;
          color: black;
          * {
            margin: 15px 0;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 820px) {
    .card-02 {
      .card-wrap {
        .Card-container {
          width: 45%;
        }
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .card-02 {
      .card-wrap {
        .Card-container {
          width: 80%;
        }
      }
    }
  }
}

// Navbar
.mainNavBar {
  position: sticky;
  background: white;
  top: 0;
  z-index: 5;
  border-bottom: 1px solid $blue2;
  .burger_container {
    display: none;
    flex-direction: column;
    align-items: center;
    z-index: 22;
    width: 100vw;
    height: 100vh;
    background-color: $white;
    position: fixed;
    .closedBtn {
      display: block;
      position: absolute;
      top: 32px;
      left: 20px;
      padding: 10px;
      font-size: 2.2rem;
      font-weight: bold;
      color: $black;
      cursor: pointer;
      transition: color 0.2s;
      &:hover {
        color: $red;
      }
      .closedBtnIcon {
        display: inline-block;
        width: 30px;
        height: 30px;
        line-height: 28px;
        text-align: center;
        background-color: $white;
        border-radius: 50%;
        // border: 2px solid $black;
      }
    }
    .burger_list_container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      margin: 25vh 0 5vh 13vw;
      .burger_list {
        text-decoration: none;
        margin: 15px 0;
      }
    }
    .burger_icon_container {
      .burger_icon {
        display: flex;
        text-decoration: none;
        align-items: center;
        justify-content: center;
        span {
          margin-left: 10px;
          font-size: 20px;
        }
      }
    }
    @media screen and (max-width: 1023px) {
      &.menuSection {
        &.active {
          display: block;
        }
      }
      .burger_container {
        display: flex;
      }
      .is-para {
        para-big {
          font-size: 2.5vw;
        }
      }
    }
  }
  .secondaryNav {
    @extend .is-blue-gradient;
    .secNavText {
      @extend .font-gothic-a1;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 20px;
      text-decoration-line: underline;
      color: $white;
      padding: 4px 0px;
      .arrow {
        padding: 20px;
        border-radius: 60px;
        height: 40px;
        width: 40px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0px 10px;
        &:after {
          content: "";
          width: 30px;
          height: 2px;
          background-color: $white;
          position: absolute;
        }
        &:before {
          content: "";
          border: solid $white;
          height: 12px;
          width: 12px;
          position: absolute;
          margin-right: -16px;
          border-width: 2px 2px 0 0;
          transform: rotate(45deg);
        }
      }
    }
    @media only screen and (max-width: 1700px) {
      .secNavText {
        font-size: 14px;
        padding: unset;
      }
    }
    @media only screen and (max-width: 1100px) {
      .secNavText {
        font-size: 12px;
        .arrow {
          &:after {
            width: 20px;
          }
          &:before {
            height: 9px;
            width: 9px;
          }
        }
      }
    }
  }
  .navbar {
    display: none;
    &.burger-menu {
      display: none;
    }
    &.menuSection {
      &.active {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    // position: fixed;
    min-height: 7.19rem;
    @extend .transition-all-03s-ease;
    box-shadow: 0px 1px 4px 0px #0000001a;
    // padding: 20px 0;
    > .container {
      flex-grow: inherit;
      @extend .container;
    }
    .burger-menu {
      display: none;
      li {
        list-style-type: none;
        width: 20px;
        height: 2px;
        background-color: black;
        margin: 5px;
      }
    }

    .navbar-brand {
      background: transparent !important;
      .navbar-item {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          @extend .transition-all-03s-ease;
          max-height: none;
        }
      }
    }
    .navbar-start {
      .navbar-item {
        font-size: 18px;
      }
    }
    .navbar-end {
      .navbar-item {
        a:first-child {
          display: flex;
          align-items: center;
          margin-bottom: 3px;
          span {
            margin-left: 5px;
          }
        }
        a {
          font-family: "Gothic A1";
          color: #5f5f5f;
          font-size: 18px;
          padding: 0px 20px;
        }
      }
      @media screen and (max-width: 1180px) {
        .mobile-number {
          display: none;
        }
      }
    }
    .navbar-menu {
      margin-left: auto;
      margin-right: auto;
      flex-grow: inherit;
      a,
      p {
        padding: 0 1.7vw;
        flex-grow: inherit;
        flex-shrink: inherit;
        // font-weight: 600;
        cursor: pointer;
        &:hover {
          background-color: transparent;
          color: $blue;
        }
        &:focus {
          color: $blue;
        }
      }
      &.burger-navigation {
        transform: translateX(120%);
        background-color: #fff;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        right: 0;
        width: 100vw;
        max-width: 400px;
        height: 100vh;
        display: flex;
        transition: all 0.3s ease;
        box-shadow: 0 0 10px 0px #00000066;
        z-index: 9;
        .navbar-start {
          @extend .full-width;
          padding-left: 10%;
        }
        .navbar-item {
          padding: 10px;
          color: $black;
          text-align: left;
        }
        .heading-02 {
          @extend .heading-02;
          margin: 0;
          text-align: left;
        }
        .accordion-section-title {
          display: inline-flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 0;
        }
      }
      &.is-active {
        transform: translateX(0);
        max-height: none;
        .navbar-start {
          display: block;
          .navbar-item {
            color: $blue;
          }
        }
        .navbar-end {
          // display: none;
        }
        .button {
          background-color: $light-blue;
          color: #fff !important;
        }
      }
    }
    .navbar-burger {
      z-index: 99;
      span {
        background-color: $black;
      }
      &.is-active {
        span {
          background-color: $black;
        }
      }
    }
    .header-call-btn {
      img {
        filter: invert(2%) sepia(0%) saturate(578%) hue-rotate(179deg)
          brightness(107%) contrast(94%);
        margin-right: 10px;
        width: 20px;
      }
    }
    &.is-fixed-top {
      // border-bottom: 1px solid $grey-lighter;
    }
    &.after-scroll {
      min-height: 7.25rem;
      .mega-dropdown-section {
        // top: 116px;
        // height: calc(100% - 116px);
      }
    }
    @media screen and (max-width: 1700px) {
      min-height: 6.19rem;
      .navbar-brand {
        .navbar-item {
          width: 80%;
        }
      }
      .navbar-start {
        .navbar-item {
          font-size: 16px;
        }
      }
      .navbar-menu {
        a,
        p {
          &:not(.is-arrowless)::after {
            top: 51%;
            width: 6px;
            height: 6px;
            border-radius: 1px;
          }
        }
      }
    }
    @media screen and (max-width: 1400px) {
      // min-height: 5rem;
      .navbar-start {
        .navbar-item {
          font-size: 14px;
        }
      }
      .navbar-brand {
        .navbar-item {
          // width: 99px;
        }
      }
      .navbar-menu {
        a,
        p {
          &:not(.is-arrowless)::after {
          }
        }
      }
    }
    @media screen and (max-width: 1023px) {
      min-height: 3.19rem;
      > .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .burger-menu {
        display: block;
        li{
          transform-origin: left;
          transition: all 0.3s ease;
        }
        &.is-active{
          position: relative;
          z-index: 9;
          .line2{
            opacity: 0;
          }
          .line1{
            transform: rotate(45deg);
          }
          .line3{
            transform: rotate(-45deg);
          }
        }
      }
      .navbar-brand {
        .navbar-item {
          width: 120px;
          padding-left: 0;
        }
      }
      .burger-menu {
        order: 3;
      }
      .navbar-menu{
        transform: translateX(110%);
        position: fixed;
        right: 0;
        top: 0;
        width: 300px;
        height: 100%;
        z-index: 9;
        transition: all 0.3s ease;
        display: flex;
        align-items: center;
        padding: 0 40px;
        &.is-active {
          transform: translateX(0);
          max-height: none;
          .button {
            background-color: $light-blue;
            color: #fff !important;
          }
        }
        .navbar-start{
          .navbar-item{
            margin: 20px 0;
          }
        }
      }
      .navbar-end{
        margin-left: auto;
        .navbar-item{
          display: flex;
          align-items: center;
          padding: 0 6px 0 0;
          a{
            padding: 0px 6px;
            display: flex;
            align-items: center;
          }
          a:first-child {
            margin-bottom: 0;
            // display: none;
          }
        }
      }
      .second-nav {
        display: flex;
        align-items: center;
        .navbar-item,
        .navbar-link {
          display: flex;
        }
      }
      .header-call-btn {
        display: none;
        span {
        }
        img {
          margin: -5px 0 0;
          width: 16px;
        }
      }
    }
    @media screen and (max-width: 767px) {
      .navbar-brand {
        .navbar-item {
          width: 160px;
        }
      }
    }
  }
}

.navbar-item {
  // &.is-hoverable,
  // &.is-active{
  .navbar-link {
    &:not(.is-arrowless)::after {
      border-color: #023915;
      right: 10%;
      border-width: 2px;
      top: 50%;
      width: 10px;
      height: 10px;
      @extend .transition-all-03s-ease;
    }
  }
  .navbar-dropdown {
    padding: 0;
    opacity: 0;
    pointer-events: none;
    display: block;
    background-color: #e5e5e5;
    box-shadow: 0 1px 3px 0px #00000026;
    .navbar-item {
      background-color: white;
      opacity: 0;
      @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
          transition-delay: #{$i * 0.1}s;
        }
      }
      @extend .transition-all-03s-ease;
    }
  }
  &.dropdown-active {
    .navbar-link {
      color: $blue;
      &:not(.is-arrowless)::after {
        top: 54%;
        transform: rotate(135deg);
      }
    }
    .navbar-dropdown {
      // background-color: transparent;
      opacity: 1;
      pointer-events: all;
      .img-column {
        opacity: 1;
      }
      .content-column {
        a {
          opacity: 1;
          @for $i from 1 through 20 {
            &:nth-child(#{$i}) {
              transition-delay: #{$i * 0.1}s;
            }
          }
          &.active {
            transform: translateX(10px);
          }
        }
      }
      .close-btn {
        display: flex;
      }
    }
  }
  // }
  @media screen and (min-width: 1025px) {
    &.has-dropdown {
      &:hover {
        .navbar-link {
          background-color: transparent;
        }
        // .navbar-dropdown{
        //     // background-color: transparent;
        //     opacity: 1;
        //     pointer-events: all;
        //     .navbar-item{
        //         opacity: 1;
        //         &:hover{
        //             color: green;
        //         }
        //     }
        // }
        // .mega-dropdown-section{
        //     opacity: 1;
        //     .img-column{
        //         opacity: 1;
        //     }
        //     .content-column{
        //         a{
        //             opacity: 1;
        //             @for $i from 1 through 20 {
        //                 &:nth-child(#{$i}) {
        //                   transition-delay: #{$i * .1}s;
        //                 }
        //             }
        //             &.active{
        //                 transform: translateX(10px);
        //             }
        //         }
        //     }
        // }
      }
    }
  }
  @media screen and (max-width: 1023px) {
    // &.is-hoverable,
    // &.is-active{
    .navbar-link {
      background: transparent;
    }
    .navbar-dropdown {
      position: absolute;
      top: 43px;
      right: 0;
      white-space: nowrap;

      position: relative;
      top: 0;
      opacity: 1;
      pointer-events: all;
      z-index: 1;
      .navbar-item {
        padding-left: 2rem;
        opacity: 1;
      }
    }
    // }
  }
}

// .mega-dropdown-section {
//   position: fixed;
//   left: 0;
//   // top: 148px;
//   // height: calc(100% - 148px);
//   // z-index: -1;
//   top: 0;
//   height: 100%;
//   width: 100%;
//   z-index: 1;
//   padding: 3rem !important;
//   opacity: 0;
//   @extend .transition-all-03s-ease;
//   .container {
//     height: 100%;
//   }
//   .columns {
//     height: 100%;
//     justify-content: center;
//     .img-column {
//       width: 56.8%;
//       opacity: 0;
//       flex-basis: auto;
//       flex-grow: inherit;
//       flex-shrink: inherit;
//       @extend .transition-all-03s-ease;
//       img {
//         max-height: none;
//         width: 100%;
//         object-fit: cover;
//         display: none;
//         &:first-child {
//           display: block;
//         }
//       }
//     }

//     .content-column {
//       width: 38%;
//       display: flex;
//       flex-direction: column;
//       flex-basis: auto;
//       flex-grow: inherit;
//       flex-shrink: inherit;
//       a {
//         color: $blue;
//         opacity: 0;
//         @extend .transition-all-03s-ease;
//         transition-delay: 0s;
//         &:hover {
//           color: $light-blue !important;
//         }
//       }
//     }
//   }
//   .close-btn {
//     display: flex;
//     position: absolute;
//     right: 3vw;
//     top: 3vw;
//     font-size: 4vw;
//     color: $black;
//     font-weight: 100;
//     @extend .butler-300;
//     transform: rotate(45deg);
//     background: transparent;
//     width: 20px;
//     height: 20px;
//     border-radius: 50%;
//     text-align: center;
//     align-items: center;
//     justify-content: center;
//     border: 1px solid transparent;
//     cursor: pointer;
//     display: none;
//   }
//   &.dropdownactive {
//     opacity: 1;
//     .img-column {
//       opacity: 1;
//     }
//     .content-column {
//       a {
//         opacity: 1;
//         @for $i from 1 through 20 {
//           &:nth-child(#{$i}) {
//             transition-delay: #{$i * 0.1}s;
//           }
//         }
//         &.active {
//           transform: translateX(10px);
//         }
//       }
//     }
//   }
//   @media screen and (max-width: 1700px) {
//     // top: 80px;
//     // height: calc(100% - 80px);
//   }
//   @media screen and (max-width: 1023px) {
//     background-color: transparent !important;
//     height: auto;
//     padding: 0rem 2rem !important;
//     transition: none;
//     .columns {
//       margin-top: 0;
//       margin-bottom: 0;
//       .img-column {
//         display: none;
//       }
//       .content-column {
//         margin: 0 !important;
//         padding: 0;
//         a {
//           opacity: 1;
//           font-size: 15px;
//           margin: 0;
//           padding-left: 0 !important;
//           color: #7d7878 !important;
//           font-family: "Ubuntu", sans-serif;
//         }
//       }
//     }
//   }
// }

//Mobile Dropdown
// #navbarBasicExample.navbar-menu {
//   @media only screen and (max-width: 768px) {
//     display: block;
//     position: fixed;
//     right: 0;
//     top: 54px;
//     // width: 300px;
//     width: 85%;
//     height: calc(100vh - 54px);
//     transform: translateX(100%);
//     transition: all 1s ease;
//     overflow-x: hidden;
//     .navbar-item {
//       width: 100%;
//     }
//     &.is-active {
//       transform: translateX(0%);
//     }
//   }
// }

// Header Search
// .header-search-wrp {
//   position: absolute;
//   right: 0;
//   width: 84%;
//   height: 100%;
//   background: #fff;
//   padding-right: 66px !important;
//   transform: translateY(-130%);
//   transition: all 0.3s ease;
//   &.is-active {
//     transform: translateY(0%);
//   }
//   form {
//     width: 100%;
//     display: flex;
//     align-items: center;
//     > .field {
//       margin-bottom: 0;
//       &:nth-child(1) {
//         width: 100%;
//         height: 100%;
//         margin-bottom: 0;
//         .control {
//           width: 100%;
//           height: 100%;
//           input {
//             background: transparent;
//             border: none;
//             height: 100%;
//             width: 100%;
//             border-left: 1px solid #dcdcdc;
//             padding-left: 20px;
//             &:focus-visible {
//               outline: none;
//             }
//           }
//         }
//       }
//     }
//   }
//   @media screen and (max-width: 1023px) {
//     width: 100%;
//     padding-right: 0px !important;
//     .navbar-item {
//       display: flex;
//     }
//   }
// }

// Sub Navigation
// .sub-nav {
//   position: sticky;
//   top: 0;
//   background-color: #fff;
//   z-index: 2;
//   .navbar {
//     display: flex;
//     justify-content: center;
//     padding: 0;
//   }
//   .navbar-item {
//     color: $gray;
//     font-size: 20px;
//     padding: 20px;
//     margin: 0 20px;
//     border-bottom: 2px solid transparent;
//     transform: translateY(2px);
//     &.active {
//       color: $orange;
//       border-bottom-color: $orange;
//     }
//   }
//   @media only screen and (max-width: 1700px) {
//     .navbar {
//       height: auto;
//       min-height: auto;
//     }
//     .navbar-item {
//       font-size: 16px;
//       line-height: normal;
//       padding: 12px 16px;
//       margin: 0 16px;
//     }
//   }
//   @media only screen and (max-width: 1200px) {
//     .navbar-item {
//       font-size: 14px;
//       padding: 10px 14px;
//       margin: 0 12px;
//     }
//   }
//   @media only screen and (max-width: 768px) {
//     // justify-content: flex-start;
//     // overflow-x: scroll;
//   }
// }
// .mob-dropdown {
//   @media only screen and (max-width: 768px) {
//     flex-wrap: wrap;
//     height: 37px;
//     overflow: hidden;
//     flex-direction: column;
//     align-items: flex-start;
//     transition: all 1s ease;
//     &:before {
//       content: attr(data-before);
//       width: 92%;
//       height: 35px;
//       text-align: left;
//       font-size: 1rem;
//       background: #545454;
//       color: #fff;
//       padding: 6px 4% 0;
//       span {
//         font-weight: 400;
//         display: block;
//         padding: 0 0 10px;
//         border-bottom: 2px solid transparent;
//         margin: 0 2.4rem;
//         color: #969696;
//         position: relative;
//         font-size: 1rem;
//         border-bottom: 1px solid #000;
//         width: 100%;
//         text-align: center;
//         padding: 5px 0;
//       }
//     }
//     &:after {
//       content: "";
//       width: 0;
//       height: 0;
//       border-left: 5px solid transparent;
//       border-right: 5px solid transparent;
//       border-top: 7px solid #fff;
//       position: absolute;
//       right: 4%;
//       top: 16px;
//       margin: auto;
//       transition: all 1s ease;
//       transform-origin: center;
//     }
//     &.open {
//       height: auto;
//       background: #fff;
//       &:after {
//         transform: rotate(180deg);
//       }
//     }
//   }
//   @media only screen and (max-width: 768px) {
//     .tab {
//       margin: 0 0.9rem;
//       &.tab.active:before,
//       &.tab.active:after {
//         content: "";
//         display: none;
//       }
//     }
//   }
// }

// .home-banner {
//   height: 100vh;
// }
// .home-banner-slider {
//   width: 100%;
//   height: 100%;
//   position: relative;
//   .desktop-banner,
//   .mobile-banner {
//     width: 100%;
//     height: 100%;
//     background-position: center;
//     background-repeat: no-repeat;
//     background-size: cover;
//     position: absolute;
//     left: 0;
//     top: 0;
//     z-index: 0;
//   }
//   div:not(.owl-nav) {
//     height: 100%;
//   }
//   > * {
//     z-index: 2;
//   }
//   .container {
//     display: flex;
//     justify-content: space-between;
//     align-items: flex-end;
//     height: 100%;
//     padding-bottom: 5%;
//   }
//   h2 {
//     @extend .heading-01;
//     &.small {
//       width: 45%;
//     }
//     &.medium {
//       width: 60%;
//     }
//   }
//   p {
//     width: 24%;
//   }
//   .owl-nav,
//   .owl-dts {
//     height: auto !important;
//   }
//   &.slider-btn-circle {
//     &.owl-carousel .owl-nav {
//       [class*="owl-"] {
//         z-index: 2;
//       }
//       .owl-prev {
//         left: 5%;
//       }
//       .owl-next {
//         right: 5%;
//       }
//     }
//   }
//   @media only screen and (max-width: 1700px) {
//   }
//   @media only screen and (max-width: 1200px) {
//     &.slider-btn-circle {
//       &.owl-carousel .owl-nav {
//         [class*="owl-"] {
//           top: inherit;
//           bottom: 42%;
//         }
//         .owl-prev {
//           // right: calc(((100vw - 960px)/2) + 50px);
//           // left: inherit;
//         }
//         .owl-next {
//           // right: calc((100vw - 960px)/2);
//         }
//       }
//     }
//   }
//   @media only screen and (max-width: 767px) {
//     h2 {
//       text-align: left;
//       &.small,
//       &.medium {
//         width: 100%;
//       }
//     }
//     .btn-big-cricle-wrp {
//       position: absolute;
//       bottom: 22%;
//       right: 20px;
//     }
//     &.slider-btn-circle {
//       &.owl-carousel .owl-nav {
//         .owl-prev {
//           right: inherit;
//           left: 5%;
//         }
//         .owl-next {
//           right: inherit;
//           left: calc(5% + 50px);
//         }
//       }
//     }
//   }
// }

// .footer-extra-content {
//   border-top: 1px solid $light-gray;
//   h2,
//   h3 {
//     @extend .heading-03;
//     color: $blue;
//     @extend .margin-b-4;
//     strong {
//       color: inherit;
//       @extend .font-gothic-a1;
//     }
//   }
//   p {
//     @extend .is-para;
//     color: $dark-gray;
//   }
// }

// footer
.footer {
  padding-bottom: 3rem;
  .content {
    .menu-list {
      list-style: none;
      .menu-label {
        text-transform: capitalize;
        a {
          color: $blue;
          padding-left: 0.5em;
          opacity: 1;
          font-size: 25px;
          @extend .butler-300;
        }
      }

      a,
      p {
        color: $dark-gray;
        font-size: 14px;
        text-align: left;
        padding-top: 6px;
        padding-bottom: 6px;
        margin-bottom: 0;
        &:hover {
          background-color: transparent;
          color: $dark-blue;
        }
      }
    }
  }
  .address {
    cursor: auto;
    &:hover {
      background-color: transparent;
    }
  }
  .social-wrp {
    display: flex;
    align-items: center;
    a {
      cursor: pointer;
      padding: 0;
      margin: 0;
      width: 24px;
      &:not(:first-child) {
        margin-left: 15px;
      }
    }
  }
  .social-share {
    display: flex;
    align-items: center;
    a {
      cursor: pointer;
      padding: 0;
      margin: 0;
      &:nth-child(2) {
        margin: 0 15px;
      }
    }
  }
  .footer-product-name {
    display: flex;
    flex-wrap: wrap;
    margin-top: 3vw;
    a {
      color: rgba(255, 255, 255, 0.8);
      font-size: 16px;
      text-align: left;
      padding: 5px 10px;
      &:hover {
        background-color: transparent;
        color: #fda452;
      }
    }
  }
  hr {
    height: 1px;
    background-color: $gray;
    margin: 4vw 0;
  }
  .copyright {
    a {
      color: $dark-gray;
      &:nth-child(2) {
        margin-left: 30px;
        margin-right: 30px;
      }
      &:hover {
        background-color: transparent;
        color: $dark-blue;
      }
    }
    p {
      color: $dark-gray;
    }
  }
  @media screen and (max-width: 1700px) {
    .content {
      .menu-list {
        .menu-label {
          &:not(:last-child) {
            margin-bottom: 0.8em;
          }
          a {
            font-size: 18px;
          }
        }
        a,
        p {
          font-size: 12px;
        }
        p {
          img {
            width: 30px;
          }
        }
      }
    }
    .social-share {
      a {
        width: 20px;
        &:nth-child(2) {
          margin: 0 10px;
        }
      }
    }
    .footer-product-name {
      margin-top: 0vw;
      a {
        font-size: 14px;
      }
    }
    hr {
      margin: 3vw 0;
    }
    .copyright {
      a {
        &:nth-child(2) {
          margin-left: 20px;
          margin-right: 20px;
        }
      }
    }
  }
  @media screen and (max-width: 1023px) {
    padding-top: 2rem;
    .content {
      .menu-list {
        margin-left: 0;
        .menu-label {
          a {
            padding-left: 0;
          }
        }
      }
    }
  }
  @media screen and (max-width: 820px) {
    .content {
      .menu-list {
        .menu-label {
          a {
            font-size: 15px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .content {
      .menu-list {
        .menu-label {
          a {
            font-size: 18px;
          }
        }
      }
    }
    .copyright {
      .column {
        &:first-child {
          display: flex;
        }
      }
      p {
        text-align: center !important;
      }
    }
  }
}

// banner 1
.homePage_banner {
  .container {
    .img-wrp {
      width: 34.7vw;
    }
  }
  @media only screen and (max-width: 700px) {
    .container {
      flex-direction: column-reverse;
      .img-wrp {
        width: 90%;
      }
      .heading-wrp {
        display: flex;
        align-items: center;
        p {
          text-align: center;
        }
      }
    }
  }
}

// Our Services
.our-services {
  .heading-wrp {
    margin-bottom: 30px;
  }
  .our-service-card-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    .our-service-card {
      margin: 20px;
      width: 30%;
      padding: 0 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .icon-img {
        width: 68px;
      }
      .para-big {
        margin: 33px 0;
      }
    }
  }
  @media only screen and (max-width: 1410px) {
    .our-service-card-box {
      .our-service-card {
        margin: 15px;
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    .our-service-card-box {
      .our-service-card {
        width: 40%;
        margin: 10px;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .our-service-card {
      width: 70%;
    }
  }
  @media only screen and (max-width: 500px){
    .our-service-card-box {
      .our-service-card {
        width: 100%;
      }
    }
  }

}

// Subscription Plans
.subscriptionPlans {
  .container {
    flex-direction: column;
    .sub_sec2 {
      display: flex;
      justify-content: space-evenly;
      .sub-Card {
        background: $offwhite;
        color: $gray3;
        font-weight: 400;
        width: 31.5vw;
        box-shadow: 13px 34px 84px rgba(121, 121, 121, 0.2);
        border-radius: 10px 10px 0 0;
        padding: 2.5vw;
        border-bottom: 10px solid #7678ed;
        h4 {
          margin-top: 10px;
          span {
            margin-left: 10px;
          }
        }
        h6 {
          margin-top: 10px;
        }
        hr {
          background: #d8d8d8;
        }
        .btnTop_space {
          margin-top: 2vw;
        }
        .is-para {
          .openAndCloseArrow {
            margin-bottom: 10px;
            &:after {
              content: "";
              margin-left: 10px;
              height: 10px;
              width: 10px;
              display: inline-block;
              transform: rotate(-45deg);
              margin-left: 10px;
              margin-bottom: 3px;
              border-left: 1px solid $black;
              border-bottom: 1px solid $black;
            }
            &.active {
              &:after {
                content: "";
                margin-bottom: 0;
                margin-right: 3px;
                margin-top: 3px;
                height: 10px;
                width: 10px;
                display: inline-block;
                transform: rotate(-45deg);
                border-left: none;
                border-bottom: none;
                border-top: 1px solid $black;
                border-right: 1px solid $black;
              }
            }
          }
        }
        .cover-sec {
          padding-left: 29px;
          &:before {
            content: "\2715";
            color: $red;
            margin-left: -29px;
          }
        }
        .subCardFea {
          display: flex;
          flex-wrap: wrap;
          ul:first-child {
            width: 60%;
          }
          ul {
            width: 50%;
            li {
              padding-left: 29px;
              margin-bottom: 2px;
              &:before {
                content: "\2713";
                color: $green;
                margin-left: -29px;
              }
            }
          }
          .subscription-troubleshooting {
            margin-top: 10px;
            h6 {
              width: 100%;
            }
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            li {
              padding-left: 29px;
              margin-bottom: 2px;
              &:before {
                content: "\2713";
                color: $green;
                margin-left: -29px;
              }
            }
          }
        }
      }
      // .sub-Card:last-child {
      //   ul:nth-child(1) {
      //     width: 50%;
      //   }
      //   ul:nth-child(2) {
      //     padding: 29px;
      //     background: #efeaff;
      //     @media only screen and (max-width: 1000px) {
      //       padding: 20px;
      //     }
      //     h6 {
      //       margin: unset;
      //     }
      //   }
      // }
    }
  }
  @media only screen and (max-width: 1440px) {
    .container {
      .heading-wrp {
        .margin-01 {
          margin: 16px 0px !important;
        }
      }
      .sub_sec2 {
        .sub-Card {
          width: 33.5vw;
        }
      }
    }
  }
  // @media only screen and (max-width: 1300px) {
  //   .container {
  //     .sub_sec2 {
  //       .sub-Card {
  //         width: 36vw;
  //       }
  //     }
  //   }
  // }
  @media only screen and (max-width: 1300px) {
    .container {
      .sub_sec2 {
        .sub-Card {
          width: 80%;
          padding: 3vw;
          margin: 20px 4.5vw;
        }
      }
    }
  }
  @media only screen and (max-width: 820px) {
    .container {
      .sub_sec2 {
        flex-direction: column;
      }
      .sub-Card {
        width: 60%;
        margin: 20px auto;
      }
    }
  }
  @media only screen and (max-width: 580px) {
    .container {
      .sub_sec2 {
        .sub-Card {
          width: 90%;
          padding: 6vw;
        }
      }
    }
  }
}

// testimonials section
.testimonials-section{
  .testimonials-card-wrap{
    display: flex;
    align-items: center;
    width: 90%;
    margin: auto;
    box-shadow: 13px 34px 84px rgba(142, 142, 142, 0.2);
    border-radius: 10px;
    background-color: $white;
    .testimonials-message{
      line-height: 2vw;
      margin-bottom: 3.5vw;
    }
    .testimonials-img-wrap{
      width:25%;
      img{
        height: 100%;
        width: 100%;
      }
    }
    .testimonials-details-wrap{
      width: 75%;
      padding: 0 40px;
    }
  }
  @media only screen and (max-width:820px) {
    .testimonials-card-wrap{
      // width:100%;
      flex-direction: column;
      .testimonials-img-wrap{
        width:30%;
        margin: 20px 0;
      }
      .testimonials-details-wrap{
        width: 100%;
        padding: 0 10px;
        .testimonials-message{
          // line-height: 3vw;
          line-height: unset;
          font-size:20px;
        }
        .testimonials-name{
          margin-bottom: 20px;
        }
      }
    }
  }
  @media only screen and (max-width:500px) {
    .testimonials-card-wrap{
    .testimonials-details-wrap{
    .testimonials-message{
      font-size:16px;
    }
    }
    .testimonials-img-wrap{
      width:40%;
    }
    .testimonials-name{
      .is-para{
        font-size:12px;
      }
      .para-medium{
        font-size: 14px;
      }
    }
    }
  }

}


//card wrapper
.card-wrap-01 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  // justify-content: flex-end;
  @media only screen and (max-width: 700px) {
    justify-content: center;
  }
}
// cards
.card-01 {
  border-radius: 10px;
  box-shadow: 13px 34px 84px rgba(142, 142, 142, 0.2);
  margin: 30px;
  padding: 1.5vw;
  .icon-img {
    padding: 14px;
    background: $gray;
    border-radius: 10px;
    width: 66px;
    img {
      width: 100%;
      display: block;
    }
  }
  &.is-white {
    background: white;
  }
  &.card-width-01 {
    width: 40.4%;
  }
  .title-margin-01 {
    margin: 23px 0px;
  }
  @media only screen and (max-width: 1680px) {
    margin: 20px;
    &.card-width-01 {
      width: 42.4%;
    }
    .title-margin-01 {
      margin: 15px 0px;
    }
  }
  @media only screen and (max-width: 1400px) {
    &.card-width-01 {
      width: 40%;
    }
    .card-wrap-01 {
      display: flex;

      justify-content: space-around;
    }
  }
  @media only screen and (max-width: 700px) {
    &.card-width-01 {
      width: unset;
    }
  }
  @media only screen and (max-width: 500px) {
    &.card-width-01 {
      padding: 15px;
    }
  }
}
.card-02 {
  &.cardSliderWrap {
    .card-wrap {
      padding: 1.6vw;
      * {
        margin: 7px 0px;
      }
      .Card-container {
        border-radius: 10px;
        &.card-type-01 {
          box-shadow: 13px 34px 84px rgba(121, 121, 121, 0.2);
          padding: 1.6vw;
        }
        &.card-type-02 {
          box-shadow: 13px 34px 84px rgba(142, 142, 142, 0.2);
          margin: 10px;
          img {
            filter: drop-shadow(13px 34px 84px rgba(142, 142, 142, 0.2));
          }
          .card-discrip-warp {
            padding: 1vw;
          }
        }
        .is-para {
          margin: 10px 0px;
        }
        &.is-white {
          background-color: #fff;
          color: unset;
        }
      }
    }
  }

  @media only screen and (max-width: 1680px) {
    &.card-type-01 {
      padding: 1.3vw 1.5vw;
    }
    .card-wrap {
      padding: 1vw;
    }
  }
  @media only screen and (max-width: 1440px) {
    .card-type-01 {
      padding: 1.3vw;
    }
  }
  @media only screen and (max-width: 1109px) {
    &.cardSliderWrap {
      .card-wrap {
        .Card-container {
          margin: 5vw;
        }
      }
    }
  }
  @media only screen and (max-width: 980px) {
    &.cardSliderWrap {
      .card-wrap {
        .Card-container {
          margin: 2.5vw;
        }
      }
    }
  }
  @media only screen and (max-width: 700px) {
    &.cardSliderWrap {
      .card-wrap {
        .Card-container {
          &.card-type-01 {
            margin: 10vw;
            padding: 3.6vw;
          }
          &.card-type-02 {
            margin: 10vw;
            .card-discrip-warp {
              padding: 3vw;
            }
          }
        }
      }
    }
  }
}
// services
.services {
  background: $offwhite;

  .card-wrap-01 {
    // width: calc(100% - var(--width-01));
    width: calc(100% - 460px);
  }
  @media only screen and (max-width: 1700px) {
    .card-wrap-01 {
      width: calc(100% - 356px);
    }
  }
  @media only screen and (max-width: 1260px) {
    .heading-wrp {
      width: 100%;
      display: flex;
      align-items: center;
      .heading-01 {
        text-align: center;
      }
      .is-para {
        text-align: center;
      }
    }
    .container {
      &.fullhd {
        flex-direction: column;
      }
      .card-wrap-01 {
        width: 80%;
      }
    }
  }
  @media only screen and (max-width: 1066px) {
    .container {
      .card-wrap-01 {
        width: unset;
      }
      .heading-wrp {
        // max-width: 70%;
      }
    }
  }
  @media only screen and (max-width: 700px) {
    .container {
      .card-wrap-01 {
        margin-top: 40px;
      }
    }
  }
}

.OurProducts {
  .container {
    flex-direction: column;
    align-items: unset;
    .heading-wrp-02 {
      .button {
        font-weight: 700;
      }
    }
  }
}

// Areas We Serve
.AreaWeServe {
  .container {
    .banner-img-wrap {
      width: 50%;
      img {
        border-radius: 11px;
      }
    }
  }
  @media only screen and (max-width: 800px) {
    .container {
      &.fullhd {
        .heading-wrp {
          display: flex;
          align-items: center;
          .is-para {
            text-align: center;
          }
        }
        .banner-img-wrap {
          width: 75%;
          margin-bottom: 30px;
        }
        flex-direction: column-reverse;
        .half-width {
          width: 100%;
        }
      }
    }
  }
}
// Read our Blogs
.Blogs {
  background-color: $offwhite;
  .container {
    flex-direction: column;
    align-items: unset;
  }
}
.slider-btn-circle.owl-theme .owl-nav [class*="owl-"] {
  border: 1px solid $light-gray2;
}
.slider-btn-circle.owl-theme .owl-nav [class*="owl-"]::after {
  background-color: $light-gray2;
}
.slider-btn-circle.owl-theme .owl-nav [class*="owl-"]::before {
  border: 2px solid $light-gray2;
  border-top: none;
  border-left: none;
}

// Inner Banner
// .inner-banner {
//   width: 100%;
//   height: 100vh;
//   position: relative;
//   .desktop-banner,
//   .mobile-banner {
//     width: 100%;
//     height: 100%;
//     background-position: center;
//     background-repeat: no-repeat;
//     background-size: cover;
//     position: absolute;
//     left: 0;
//     top: 0;
//   }
//   > * {
//     z-index: 2;
//   }
//   .container {
//     display: flex;
//     justify-content: space-between;
//     align-items: flex-end;
//     height: 100%;
//     padding-bottom: 3%;
//   }
//   .text-wrp {
//     &.small {
//       width: 45%;
//       width: 60%;
//     }
//     &.medium {
//       width: 55%;
//     }
//   }
//   h1 {
//     @extend .heading-01;
//   }
//   p {
//     width: 24%;
//   }
//   &.verticle-center {
//     .container {
//       align-items: center;
//     }
//   }
//   &.without-img {
//     height: 79vh;
//   }
//   @media only screen and (max-width: 1700px) {
//     // max-height: 400px;
//     .text-wrp {
//       &.medium {
//         // width: 48%;
//       }
//     }
//   }
//   @media only screen and (max-width: 1200px) {
//     p {
//       width: 36%;
//     }
//   }
//   @media only screen and (max-width: 1023px) {
//     p {
//       width: 45%;
//     }
//   }
//   @media only screen and (max-width: 767px) {
//     .heading-01 {
//       text-align: left;
//     }
//     p {
//       width: 60%;
//     }
//     .text-wrp {
//       &.small,
//       &.medium {
//         width: 100%;
//       }
//     }
//     .btn-big-cricle-wrp {
//       position: absolute;
//       bottom: 22%;
//       right: 20px;
//     }
//   }
//   @media only screen and (max-width: 480px) {
//     p {
//       width: 90%;
//     }
//   }
// }

// without Inner Banner
// .without-inner-banner {
//   width: 100%;
//   // height: 116px;
//   height: 148px;
//   &.bg-blue {
//     background-color: $dark-blue;
//   }
//   &.bg-white {
//     background-color: $white;
//   }
//   @media only screen and (max-width: 1700px) {
//     height: 105px;
//   }
//   @media only screen and (max-width: 1023px) {
//     height: 93px;
//   }
//   @media only screen and (max-width: 767px) {
//     height: 80px;
//   }
// }

// Systems
// .systems {
//   .owl-carousel .owl-item img {
//     width: 70px;
//   }
// }

// Download List
// .download-item {
//   display: flex;
//   align-items: center;
//   background-color: $lightest-blue;
//   .content-wrp {
//     margin: 0 3%;
//   }
//   .img-wrp {
//     img {
//       display: block;
//     }
//   }
//   .button {
//     margin: 0 3% 0 auto;
//     width: 20%;
//   }
//   @media screen and (max-width: 1700px) {
//     .img-wrp {
//       width: 120px;
//     }
//     .button {
//       // width: 16%;
//       svg {
//         width: 15px;
//       }
//     }
//   }
//   @media screen and (max-width: 1200px) {
//     .img-wrp {
//       width: 100px;
//     }
//     .button {
//       width: 18%;
//       svg {
//         width: 12px;
//       }
//     }
//     .is-para {
//       &.para-medium {
//         font-size: 12px;
//       }
//     }
//   }
//   @media screen and (max-width: 1023px) {
//     .img-wrp {
//       // width: 80px;
//     }
//     .content-wrp {
//       width: calc(100% - 230px);
//     }
//     .button {
//       width: 150px;
//       svg {
//         // width: 12px;
//       }
//     }
//   }
//   @media screen and (max-width: 767px) {
//     flex-wrap: wrap;
//     align-items: flex-start;
//     .img-wrp {
//       width: 80px;
//     }
//     .content-wrp {
//       width: calc(100% - 100px);
//       margin: 10px;
//     }
//     .button {
//       margin: 0px auto 16px 90px;
//     }
//   }
// }

// subscribe
// .subscribe {
//   .container {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//   }
//   form {
//     display: flex;
//     align-items: center;
//     height: 100%;
//   }
//   @media screen and (max-width: 768px) {
//     .columns {
//       &.full-width {
//         width: calc(100% + 1.5rem);
//       }
//       .column {
//         &:first-child {
//           padding-bottom: 0;
//         }
//         &.pr-0 {
//           padding-right: 0.75rem !important;
//         }
//       }
//     }
//   }
// }

// Make Enquiry
// .make-enquiry {
//   position: relative;
//   padding: 12vw 0 4vw 0;
//   h2 {
//     color: #909090;
//     font-size: 6.2vw;
//     text-transform: uppercase;
//     @extend .font-gothic-a1;
//   }
//   .btn-big-cricle-wrp {
//     position: absolute;
//     left: 0;
//     right: 0;
//     margin: auto;
//     top: 4vw;
//     cursor: pointer;
//   }
//   marquee {
//     cursor: pointer;
//   }
//   @media screen and (max-width: 767px) {
//     padding: 80px 0 50px 0;
//     h2 {
//       font-size: 55px;
//     }
//     .btn-big-cricle-wrp {
//       top: 20px;
//     }
//   }
// }

// // Find a dealer
// .find-dealer {
//   .column {
//     margin-right: 5%;
//     &:last-child {
//       position: absolute;
//       right: -18%;
//       bottom: 0;
//     }
//   }
//   .select {
//     &.is-primary {
//       select {
//         color: #93a2bc;
//       }
//     }
//   }
//   @media screen and (max-width: 768px) {
//     .heading-01 {
//       text-align: center;
//     }
//     .column {
//       margin: 0 auto;
//       width: 80%;
//       &:last-child {
//         position: static;
//         text-align: center;
//         width: 100%;
//       }
//     }
//     .btn-big-cricle-wrp {
//       margin: auto;
//     }
//   }
// }

// Checkout
.checkout-section,
.account-section {
  svg {
    circle {
    }
    text,
    path {
      font-size: 22px;
      font-weight: 300;
    }
  }
  .de-active {
    opacity: 0.5;
    pointer-events: none;
  }
  .active {
    svg {
      circle {
        // fill: #022f11;
        stroke: $blue;
      }
      text,
      path {
        // fill: #fff;
        stroke: $blue;
      }
    }

    .columns {
      .column {
        span {
          color: $blue !important;
        }
      }
    }
  }
  .completed {
    opacity: 1;
    pointer-events: none;
  }
  @media only screen and (max-width: 500px) {
    margin: 0 !important;
  }
}

.shopping-cart-tags {
  display: flex;
  justify-content: flex-end;
  // Sale Tag
  .sale-tag {
    margin-top: 3px;
    font-size: 0.65rem !important;
    &.is-absolute {
      // position: absolute;
      // right: -10px;
      // top: 5.5rem;
      // border-radius: 50%;
      // width: 46px;
      // height: 46px;
      // z-index: 1;
      // &.left-bottom{
      //     left: -10px !important;
      //     bottom: 2rem !important;
      //     right: inherit;
      //     top: inherit;
      // }
    }
    @media only screen and (max-width: 767px) {
      &.is-small {
        font-size: 0.55rem;
      }
      &.is-absolute {
        right: -6px;
        width: 36px;
        height: 36px;
      }
    }
  }

  // wishlist tag
  .wishlist-tag {
    // height: 2em;
    // font-weight: bold;
    // margin-top: 0.2rem;
    // pointer-events: none;
    &.is-absolute {
      // position: absolute;
      right: 52%;
      top: 12%;
      // border-radius: 50%;
      // padding: 0px;
      // width: 45px;
      // height: 45px;
      // z-index: 1;
      // background-color: #ffffff;
      // border: 1px solid #023915;
    }
    @media only screen and (max-width: 767px) {
      &.is-small {
        font-size: 0.55rem;
      }
      &.is-absolute {
        right: -6px;
        width: 36px;
        height: 36px;
      }
    }
  }
}

/* Whatsapp Start*/
// .whatsapp {
//   position: fixed;
//   right: 10px;
//   bottom: 12px;
//   z-index: 9999;
//   border-radius: 50%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   transform: translateX(100px);
//   animation: whatsapp 1s ease 2s forwards;
// }
// @keyframes whatsapp {
//   0% {
//     transform: translateX(100px);
//   }
//   100% {
//     transform: translateX(0px);
//   }
// }
// .whatsapp:before,
// .whatsapp:after {
//   content: "";
//   background: $light-blue;
//   width: 100%;
//   height: 100%;
//   // border: 2px solid #fff;
//   border-radius: 50%;
//   z-index: -2;
//   position: absolute;
//   left: 0;
//   right: 0;
//   opacity: 0;
//   animation: whatsapp-after 3s ease infinite;
// }
// @keyframes whatsapp-after {
//   0% {
//     width: 80%;
//     height: 80%;
//     left: 10%;
//     right: 10%;
//     opacity: 1;
//   }
//   100% {
//     width: 200%;
//     height: 200%;
//     left: -50%;
//     right: -50%;
//     opacity: 0;
//   }
// }
// .whatsapp:before {
//   animation-delay: 0.8s;
// }
// .footer-call-btn {
//   width: 38px;
//   height: 38px;
//   bottom: 70px;
//   right: 12px;
//   // overflow: hidden;
//   display: none;
// }
// .footer-call-btn span {
//   background: $blue;
//   width: 100%;
//   height: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   border-radius: 50%;
// }
// .footer-call-btn img {
//   width: 40%;
//   position: relative;
//   z-index: 2;
//   filter: invert(100%) sepia(0%) saturate(7468%) hue-rotate(41deg)
//     brightness(99%) contrast(107%);
// }
// @media screen and (max-width: 1023px) {
//   .whatsapp {
//     // bottom: 65px;
//   }
//   .footer-call-btn {
//     display: flex;
//   }
// }
/* Whatsapp End*/

//Margin
.margin-01 {
  margin: 33.5px 0px !important;
  &.margin-4side {
    margin: 35px;
  }
}
// Bottom
.margin-b-1 {
  margin-bottom: 6vw !important;
  @media only screen and (max-width: 1700px) {
    // margin-bottom: 3vw !important;
  }
}
// .margin-b-2 {
//   margin-bottom: 3vw !important;
//   @media only screen and (max-width: 1700px) {
//     // margin-bottom: 16px;
//   }
// }
.margin-b-3 {
  margin-bottom: 2vw;
  @media only screen and (max-width: 1700px) {
    margin-bottom: 12px;
  }
}
.margin-b-4 {
  margin-bottom: 15px;
  @media only screen and (max-width: 1700px) {
    margin-bottom: 8px;
  }
}
// Top
.margin-t-1 {
  margin-top: 4vw !important;
  @media only screen and (max-width: 1700px) {
    margin-top: 1.5vw;
  }
}
.margin-t-2 {
  margin-top: 3vw !important;
  @media only screen and (max-width: 1700px) {
    margin-top: 16px;
  }
}
.margin-t-3 {
  margin-top: 2vw;
  @media only screen and (max-width: 1700px) {
    margin-top: 12px;
  }
}
.margin-t-4 {
  margin-top: 15px;
  @media only screen and (max-width: 1700px) {
    margin-top: 8px;
  }
}

//padding
// Bottom
.padding-b-1 {
  padding-bottom: 4vw !important;
  @media only screen and (max-width: 1700px) {
    padding-bottom: 1.5vw;
  }
}
// .padding-b-2 {
//   padding-bottom: 3vw !important;
//   @media only screen and (max-width: 1700px) {
//     padding-bottom: 16px;
//   }
// }
.padding-b-3 {
  padding-bottom: 2vw !important;
  @media only screen and (max-width: 1700px) {
    // padding-bottom: 12px;
  }
}
// .padding-b-4 {
//   padding-bottom: 15px !important;
//   @media only screen and (max-width: 1700px) {
//     padding-bottom: 8px;
//   }
// }
// Top
// .padding-t-1 {
//   padding-top: 4vw !important;
//   @media only screen and (max-width: 1700px) {
//     padding-top: 1.5vw;
//   }
// }
// .padding-t-2 {
//   padding-top: 3vw !important;
//   @media only screen and (max-width: 1700px) {
//     padding-top: 16px;
//   }
// }
// .padding-t-3 {
//   padding-top: 2vw !important;
//   @media only screen and (max-width: 1700px) {
//     padding-top: 12px !important;
//   }
// }
// .padding-t-4 {
//   padding-top: 15px;
//   @media only screen and (max-width: 1700px) {
//     padding-top: 8px;
//   }
// }

@media only screen and (max-width: 1700px) {
  .mt-5 {
    margin-top: 1rem !important;
  }
  //Margin
  .margin-01 {
    margin: 20px 0px !important;
  }
}
@media only screen and (max-width: 1200px) {
  .padding-m-t-1 {
    padding-top: 1rem;
  }
}
@media only screen and (max-width: 1023px) {
  .products-cate-sec1 .product_slider_box,
  .home-sec1 .product_slider_box {
    padding-bottom: 140px;
  }
  .products-cate-sec1 .view_now,
  .home-sec1 .view_now {
    -webkit-transform: translateX(0%);
    -ms-transform: translateX(0%);
    transform: translateX(0%);
  }
  .products-cate-sec1 .viewnow_btn .view_now,
  .home-sec1 .viewnow_btn .view_now {
    left: 0% !important;
    right: 0 !important;
    margin: auto;
    top: inherit;
    bottom: 100px;
    position: absolute !important;
  }
  .products-cate-sec1 .buynow_btn .view_now,
  .home-sec1 .buynow_btn .view_now {
    left: 0% !important;
    right: 0 !important;
    margin: auto;
  }
}
@media only screen and (max-width: 600px) {
  .products-cate-sec1 .product_slider_box,
  .home-sec1 .product_slider_box {
    padding-bottom: 110px;
  }
  .products-cate-sec1 .viewnow_btn .view_now,
  .home-sec1 .viewnow_btn .view_now {
    bottom: 64px;
  }
}

// // home-our-products start
// .home-our-products {
//   position: relative;
//   padding-bottom: 10vw;
//   margin-bottom: 4vw;
//   &:after {
//     content: "";
//     position: absolute;
//     left: 5%;
//     bottom: 0%;
//     width: 90%;
//     height: 84%;
//     z-index: -1;
//     background-color: #e7f8ff;
//   }
//   @media only screen and (max-width: 767px) {
//     padding-bottom: 20vw;
//   }
// }

// // home-our-products end

// // home-about-section start
// .home-about-section {
//   padding-top: 10vw;
//   .container {
//     > .heading-01 {
//       width: 30vw;
//       width: 80vw;
//       position: absolute;
//       left: 0;
//       top: 0;
//     }
//   }
//   .founder-img {
//     padding: 12.2% 0 0 23%;
//     position: relative;
//     img {
//       width: 33.8%;
//       // opacity: 0;
//     }
//     .btn-big-cricle-wrp {
//       position: absolute;
//       left: 12.5%;
//       bottom: 21%;
//     }
//   }
//   .content-area {
//     position: absolute;
//     right: -10%;
//     bottom: 3%;
//     width: 54%;

//     > div {
//       display: flex;
//       align-items: center;
//       justify-content: flex-start;
//       padding: 1.5vw 25% 1.5vw 0;

//       p {
//         &:first-child {
//           width: 45%;

//           span {
//             line-height: 1;
//           }
//         }

//         &:last-child {
//           width: 55%;
//         }
//       }
//     }
//   }
//   @media screen and (max-width: 1700px) {
//     .content-area {
//       > div {
//         padding: 1vw 25% 1vw 0;
//       }
//     }
//   }
//   @media screen and (max-width: 1023px) {
//     padding-bottom: 240px;
//     position: relative;
//     .container {
//       display: flex;
//       flex-wrap: wrap;
//       position: static;
//       > .heading-01 {
//         top: 20%;
//         left: 10%;

//         position: static;
//         margin: auto;
//         text-align: center;
//       }
//     }
//     .founder-img {
//       padding: 0;
//       display: flex;
//       justify-content: flex-end;
//       position: static;

//       justify-content: center;
//       margin: 20px 0 0;
//       img {
//         // margin-right: 10%;
//         width: 50%;
//       }
//       .btn-big-cricle-wrp {
//         left: 0;
//         right: 0;
//         bottom: 2%;
//         margin: auto;
//       }
//     }
//     .content-area {
//       position: relative;
//       width: 80%;
//       right: inherit;
//       left: 10%;
//       top: 40px;
//       > div {
//         padding: 1vw 10% 1vw 0;
//       }
//     }
//   }
//   @media screen and (max-width: 767px) {
//     padding-bottom: 160px;
//     .container {
//       > .heading-01 {
//         top: 28vw;
//         left: 4%;
//         width: 90%;
//       }
//     }
//     .founder-img {
//       img {
//         // margin-right: 4%;
//         // opacity: 0;
//         width: 60%;
//       }
//     }
//     .content-area {
//       width: 96%;
//       left: 2%;
//       top: 20px;
//       > div {
//         padding: 10px 0;
//         p {
//           &:first-child {
//             width: 40%;
//           }
//           &:last-child {
//             width: 60%;
//           }
//         }
//       }
//     }
//   }
// }
// // home-about-section end

// // home-renolit-alkorplan-section start
// .home-renolit-alkorplan-section {
//   position: relative;
//   padding-top: 8vw;
//   padding-bottom: 8vw;
//   > .img-wrp {
//     width: 73.5%;
//   }
//   .container {
//     position: absolute;
//     left: 0;
//     right: 0;
//     top: 8vw;
//   }
//   .content-wrp {
//     width: 25vw;
//     .is-para {
//       width: 370px;
//     }
//   }
//   .btn-big-cricle-wrp {
//     position: absolute;
//     right: 11%;
//     bottom: -21%;
//   }
//   .home-renolit-four-img-wrp {
//     img {
//       width: 9.5vw;
//       // height: 183px;
//       position: absolute;
//     }

//     .img-01 {
//       left: 9%;
//       top: -74%;
//     }

//     .img-02 {
//       left: 0;
//       // bottom: -145%;
//       // bottom: -170%;
//       bottom: -115%;
//     }

//     .img-03 {
//       right: 13%;
//       top: -40%;
//     }

//     .img-04 {
//       right: 7%;
//       // bottom: -110%;
//       // bottom: -130%;
//       bottom: -80%;
//     }
//   }
//   @media screen and (max-width: 1700px) {
//     .content-wrp {
//       .is-para {
//         width: 280px;
//       }
//     }
//   }
//   @media screen and (max-width: 1200px) {
//     .content-wrp {
//       .is-para {
//         width: 250px;
//       }
//     }
//   }
//   @media screen and (max-width: 1023px) {
//     > .img-wrp {
//       width: 94%;
//       padding: 120px 0 0;
//       width: 90%;
//       margin: auto;
//       img {
//         max-width: none;
//         width: 200%;
//         transform: translateX(-49.3%);
//       }
//     }
//     .container {
//       position: static;
//     }
//     .content-wrp {
//       width: 100%;
//       .heading-01 {
//         position: absolute;
//         top: 70px;
//         left: 0;
//         right: 0;
//         text-align: center;
//         br {
//           display: none;
//         }
//       }
//       .is-para {
//         margin: 40px auto;
//         text-align: center;
//         width: 50%;
//       }
//     }
//     .btn-big-cricle-wrp {
//       position: static;
//       margin: 0 auto 10px;
//     }
//     .home-renolit-four-img-wrp {
//       display: none;
//     }
//   }
//   @media screen and (max-width: 767px) {
//     > .img-wrp {
//       padding: 110px 0 0;
//     }
//     .content-wrp {
//       .is-para {
//         margin: 30px auto;
//         width: 80%;
//       }
//     }
//   }
// }
// // home-renolit-alkorplan-section end

// // our-services-section start
// .our-services {
//   padding-top: 8vw;
//   .col-30-70-wrp {
//     .content-wrp {
//       margin-top: -13vw;
//     }
//   }
//   .home-our-services-slider {
//     &.slider-btn-circle {
//       .owl-nav {
//         [class*="owl-"] {
//           top: inherit;
//           bottom: 22%;
//         }

//         .owl-prev {
//           left: inherit;
//           right: calc(((100% - 85%) / 2) + 20.5%);
//         }

//         .owl-next {
//           right: calc(((100% - 85%) / 2) + 15%);
//         }
//       }
//     }
//   }
//   @media screen and (max-width: 1200px) {
//     padding-top: 12vw;
//     .col-30-70-wrp {
//       .content-wrp {
//         margin-top: -6vw;
//       }
//     }
//     .home-our-services-slider {
//       &.slider-btn-circle {
//         .owl-nav {
//           [class*="owl-"] {
//             bottom: 12%;
//           }

//           .owl-prev {
//             left: inherit;
//             right: calc(((100% - 85%) / 2) + 20.5%);
//           }

//           .owl-next {
//             right: calc(((100% - 85%) / 2) + 15%);
//           }
//         }
//       }
//     }
//   }
//   @media screen and (max-width: 1023px) {
//     .container {
//       > .heading-01 {
//         text-align: center;
//       }
//     }
//     .home-our-services-slider {
//       &.slider-btn-circle {
//         .owl-nav {
//           [class*="owl-"] {
//             bottom: 12%;
//           }

//           .owl-prev {
//             left: inherit;
//             right: 22%;
//           }

//           .owl-next {
//             right: 15%;
//           }
//         }
//       }
//     }
//   }
//   @media screen and (max-width: 767px) {
//     .heading-01 {
//       margin-top: 30px;
//       text-align: center;
//     }
//     .col-30-70-wrp {
//       .content-wrp {
//         margin-top: 0;
//       }
//     }
//     .home-our-services-slider {
//       padding-bottom: 100px;
//       .item-wrp {
//         padding-top: 30px;
//       }
//       &.slider-btn-circle {
//         .owl-nav {
//           [class*="owl-"] {
//             bottom: 30px;
//           }
//           .owl-prev {
//             left: 50%;
//             right: inherit;
//             transform: translate(-130%, 0) rotate(180deg);
//           }
//           .owl-next {
//             right: 50%;
//             transform: translate(130%, 0px);
//           }
//         }
//       }
//     }
//   }
// }
// // our-services-section end

// // home-completed-projects start
// .home-completed-projects {
//   .container {
//     > .heading-01 {
//       margin: 0 0 -3.1% 24%;
//       position: relative;
//       z-index: 2;
//     }
//   }
//   .home-our-services-slider {
//     .item-wrp {
//       margin-bottom: 2vw;
//     }
//     &.slider-btn-circle {
//       padding-bottom: 0;

//       .owl-nav {
//         [class*="owl-"] {
//           top: inherit;
//           bottom: 4%;
//         }

//         .owl-prev {
//           right: 3.5%;
//         }

//         .owl-next {
//           right: -2.5%;
//         }
//       }
//     }
//   }
//   @media screen and (max-width: 1023px) {
//     .home-our-services-slider {
//       &.slider-btn-circle {
//         .owl-nav {
//           .owl-prev {
//             right: 50px;
//           }
//           .owl-next {
//             right: -4px;
//           }
//         }
//       }
//     }
//   }
//   @media screen and (max-width: 767px) {
//     .container {
//       > .heading-01 {
//         margin: 0;
//         text-align: center;
//       }
//     }
//     .home-our-services-slider {
//       &.slider-btn-circle {
//         .owl-nav {
//           [class*="owl-"] {
//             bottom: -40px;
//           }
//           .owl-prev {
//             left: 50%;
//             right: inherit;
//             transform: translate(-130%, 0) rotate(180deg);
//           }
//           .owl-next {
//             right: 50%;
//             transform: translate(130%, 0px);
//           }
//         }
//       }
//     }
//   }
// }

// // home-completed-projects end

// // home-blog-slider start
// .home-blog-section {
//   .container {
//     overflow: hidden;
//   }
//   .home-blog-slider {
//     width: 40%;
//     .owl-stage-outer {
//       overflow: visible;
//     }
//     .owl-item {
//       .item {
//         padding: 16% 12.5% 0;
//         // @extend .transition-all-03s-ease;
//         transition: all 1s ease;
//       }
//       &.active {
//         .item {
//           padding: 0;
//         }
//       }
//     }
//     &.slider-btn-circle {
//       .owl-nav {
//         [class*="owl-"] {
//           left: 130%;
//           right: inherit;
//         }

//         .owl-prev {
//           top: 38%;
//         }

//         .owl-next {
//           top: 48%;
//         }
//       }
//     }
//   }
//   @media screen and (max-width: 1023px) {
//     .home-blog-slider {
//       &.slider-btn-circle {
//         .owl-nav {
//           [class*="owl-"] {
//             left: 128%;
//           }
//         }
//       }
//     }
//   }
//   @media screen and (max-width: 768px) {
//     .home-blog-slider {
//       width: 80%;
//       .owl-stage-outer {
//         overflow: hidden;
//       }
//       .owl-item {
//         .item {
//           padding: 0;
//         }
//         &.active {
//           .item {
//             padding: 0;
//           }
//         }
//       }
//       &.slider-btn-circle {
//         .owl-nav {
//           [class*="owl-"] {
//             left: inherit;
//             right: -18%;
//           }
//           .owl-prev {
//             top: 32%;
//           }
//           .owl-next {
//             top: 40%;
//           }
//         }
//       }
//     }
//   }
//   @media screen and (max-width: 480px) {
//     .home-blog-slider {
//       &.slider-btn-circle {
//         .owl-nav {
//           [class*="owl-"] {
//             right: -24%;
//           }
//           .owl-prev {
//             top: 24%;
//           }
//           .owl-next {
//             top: 36%;
//           }
//         }
//       }
//     }
//   }
// }

// // home-blog-slider end

// // Our Clients section start
// .our-clients-section {
//   overflow: hidden;
//   padding-bottom: 10vw;
// }

// .our-clients-slider {
//   // width: 90%;
//   border-top: 1px solid #364766;
//   border-bottom: 1px solid #364766;
//   padding: 3vw 0;
//   overflow: visible;

//   &.owl-carousel {
//     .owl-stage-outer {
//       overflow: visible;
//     }
//   }

//   .item {
//     border-right: 1px solid #bababa;
//     padding: 5vw 3vw;
//     display: flex;
//     align-items: center;
//     justify-content: center;

//     .img-wrp {
//       width: auto;
//       max-width: 150px;
//       max-height: 150px;
//     }
//   }
//   @media screen and (max-width: 1700px) {
//     .item {
//       .img-wrp {
//         max-width: 120px;
//         max-height: 120px;
//       }
//     }
//   }
//   @media screen and (max-width: 1200px) {
//     .item {
//       .img-wrp {
//         max-width: 80px;
//         max-height: 80px;
//       }
//     }
//   }
// }

// // Our Clients section end

// // testimonials section start
// // .testimonials-section {
// //   padding-bottom: 12vw;
// //   @media only screen and (max-width: 1023px) {
// //     padding-bottom: 130px;
// //   }
// // }

// .testimonials-slider {
//   width: 90%;
//   margin: auto;
//   .item {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     > p {
//       width: 28%;
//       margin: auto 0 14%;
//     }
//   }
//   .content-wrp {
//     display: flex;
//     justify-content: center;
//     flex-direction: column;
//     width: 30%;
//   }
//   .img-wrp {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     width: 30%;
//     margin: 0 7% 0 5%;
//   }
//   &.slider-btn-circle {
//     .owl-nav {
//       [class*="owl-"] {
//         top: inherit;
//         bottom: 4%;
//       }
//       .owl-prev {
//         left: inherit;
//         right: 22.4%;
//       }
//       .owl-next {
//         right: 16.4%;
//       }
//     }
//   }
//   @media only screen and (max-width: 1023px) {
//     .item {
//       flex-wrap: wrap;
//       > p {
//         width: 70%;
//         margin: 0;
//         order: 3;
//         text-align: center;
//       }
//     }
//     .content-wrp {
//       width: 100%;
//       order: 2;
//       text-align: center;
//       padding: 40px 0 30px;
//       .heading-01 {
//         text-align: center;
//       }
//     }
//     .img-wrp {
//       width: 50%;
//       margin: 0 7% 0 5%;
//       order: 1;
//     }
//     &.slider-btn-circle {
//       .owl-nav {
//         [class*="owl-"] {
//           bottom: -80px;
//         }
//         .owl-prev {
//           left: 50%;
//           right: inherit;
//           transform: translate(-130%, 0) rotate(180deg);
//         }
//         .owl-next {
//           right: 50%;
//           transform: translate(130%, 0px);
//         }
//       }
//     }
//   }
//   @media only screen and (max-width: 767px) {
//     .item {
//       > p {
//         width: 100%;
//       }
//     }
//   }
// }

// .testimonials-img-wrp {
//   img {
//     width: 9.5vw;
//     position: absolute;
//   }
//   .img-01 {
//     left: 0;
//     top: 0;
//   }
//   .img-02 {
//     left: 4%;
//     bottom: -55%;
//   }
//   .img-03 {
//     right: 3%;
//     top: -34%;
//   }
//   .img-04 {
//     right: 0;
//     bottom: -20%;
//   }
//   @media only screen and (max-width: 1023px) {
//     display: none;
//   }
// }

// // testimonials section end

// // Awards section start
// .awards-section {
//   overflow: hidden;
//   padding-top: 8vw;
// }

// .awards-slider {
//   // width: 90%;
//   overflow: visible;
//   &.owl-carousel {
//     .owl-stage-outer {
//       overflow: visible;
//     }
//   }
//   .item {
//     padding: 0 20%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     flex-direction: column;
//     text-align: center;

//     .img-wrp {
//       width: auto;
//       max-width: 120px;
//       max-height: 220px;
//     }
//   }
//   @media only screen and (max-width: 1700px) {
//     .item {
//       padding: 0 10%;
//       .img-wrp {
//         max-width: 90px;
//         max-height: 190px;
//       }
//     }
//   }
//   @media only screen and (max-width: 1200px) {
//     .item {
//       padding: 0 10%;
//       .img-wrp {
//         max-width: 70px;
//         max-height: 140px;
//       }
//     }
//   }
// }

// // Awards section end

// // Breadcrumb start
// .breadcrumb-wrp {
//   .breadcrumb {
//     // display: flex;
//     // justify-content: flex-start;
//     // align-items: center;
//     // padding: 30px 0;
//     // margin-bottom: 0;
//     // background-color: transparent;
//     a {
//       color: $blue;
//     }

//     li + li {
//       &::before {
//         content: "/";
//         color: $blue;
//         opacity: 0;
//       }
//     }
//   }
//   &.left-center {
//     .breadcrumb {
//       justify-content: center;
//     }
//   }
//   &.is-bg-dark-blue {
//     .breadcrumb {
//       li + li {
//         &::before {
//           color: $white;
//         }
//       }

//       a {
//         color: $white;
//       }
//     }
//   }
//   @media only screen and (max-width: 1700px) {
//     .breadcrumb {
//       font-size: 12px;
//       // padding: 20px 0;
//       h1 {
//         font-size: 12px;
//       }
//     }
//   }
//   @media only screen and (max-width: 767px) {
//     .breadcrumb {
//       font-size: 10px;
//     }
//   }
// }

// // Breadcrumb end

// // without Inner Banner end
// // terms and condition start
// .article-content {
//   img {
//     &:first-child {
//       // margin-left: calc(((100vw - 1344px) / 2) * -1);
//       // width: calc(1344px + ((100vw - 1344px) / 2));
//       margin-left: calc(((100vw - 1314px) / 2) * -1);
//       width: calc(1344px + ((100vw - 1374px) / 2));
//       max-width: none;
//     }
//   }
//   h2 {
//     font-size: 4.2vw;
//     line-height: 4.2vw;
//     font-family: "Butler";
//     text-align: left;
//     margin: 4vw 0 3vw;
//     color: #192c6a;
//     @media only screen and (max-width: 1700px) {
//       // font-size: 20px;
//     }
//     @media only screen and (max-width: 1200px) {
//       font-size: 16px;
//     }
//     @media only screen and (max-width: 767px) {
//       font-size: 14px;
//     }
//   }
//   p,
//   li {
//     color: #5c5c5c;
//     font-size: 20px;
//     &:not(:last-child) {
//       margin-bottom: 30px;
//     }
//     @media only screen and (max-width: 1700px) {
//       font-size: 15px;
//       &:not(:last-child) {
//         margin-bottom: 20px;
//       }
//     }
//     @media only screen and (max-width: 767px) {
//       font-size: 12px;
//       &:not(:last-child) {
//         margin-bottom: 12px;
//       }
//     }
//   }
//   ul {
//     &:not(:last-child) {
//       margin-bottom: 30px;
//     }
//     li {
//       position: relative;
//       padding-left: 20px;
//       &:before {
//         content: "";
//         background-color: $dark-gray;
//         width: 5px;
//         height: 5px;
//         border-radius: 50%;
//         position: absolute;
//         left: 0;
//         top: 9px;
//       }
//       &:not(:last-child) {
//         margin-bottom: 5px;
//       }
//     }
//     @media only screen and (max-width: 1700px) {
//       &:not(:last-child) {
//         margin-bottom: 20px;
//       }
//     }
//     @media only screen and (max-width: 767px) {
//       &:not(:last-child) {
//         margin-bottom: 12px;
//       }
//     }
//   }
//   @media only screen and (max-width: 1700px) {
//     img {
//       &:first-child {
//         // margin-left: calc(((100vw - 1152px) / 2) * -1);
//         // width: calc(1152px + ((100vw - 1152px) / 2));
//         margin-left: calc(((100vw - 1122px) / 2) * -1);
//         width: calc(1152px + ((100vw - 1182px) / 2));
//       }
//     }
//   }
//   @media only screen and (max-width: 1200px) {
//     img {
//       &:first-child {
//         margin-left: calc(((100vw - 930px) / 2) * -1);
//         width: calc(960px + ((100vw - 990px) / 2));
//       }
//     }
//   }
//   @media only screen and (max-width: 1023px) {
//     img {
//       &:first-child {
//         margin-left: -15px;
//         width: 90%;
//       }
//     }
//   }
// }

// // terms and condition end

// // Career
// // Work With Us start
// .work-with-us {
//   .tab-wrp {
//     .tab {
//       color: #5c5c5c;
//       padding: 10px 20px;
//       margin: 0 3vw;
//       border-bottom: 1px solid transparent;
//       &:first-child {
//         margin-left: 0;
//       }
//       &.active {
//         color: #192c6a;
//         border-bottom-color: #192c6a;
//       }
//     }
//   }
//   .tabcontent {
//     > div {
//       margin-top: 2vw;
//     }
//   }
// }
// // Work With Us ends

// // Contact Us start
// .connect-with-us {
//   @media screen and (max-width: 769px) {
//     padding: 20px 0 10vw 0;
//     .contact-title-wrap{
//       h2{
//         margin:15px 0;
//       }
//       p{
//         margin-bottom: 15px;
//       }
//       *{
//         text-align: center;
//         max-width: unset;
//       }
//     }
//   }
// }
// @media screen and (max-width: 769px) {
//   .contact-us-title{
//     margin-bottom: 15px;
//   }
// }
// // Contact Us ends

// // Blog detail start
// .social-share {
//   display: flex;
//   align-items: center;
//   flex-direction: row;
//   button {
//     cursor: pointer;
//     margin: 0;
//     &:nth-child(2) {
//       margin: 0 15px;
//     }
//   }
//   @media screen and (max-width: 1700px) {
//     width: auto;
//     flex-grow: inherit;
//     button {
//       width: 20px;
//       &:nth-child(2) {
//         margin: 0 10px;
//       }
//     }
//   }
// }
// // Blog detail ends

// // quality-ladders-section start
// .quality-ladders-section {
//   position: relative;
//   .img-wrp {
//     position: relative;
//     &:before {
//       content: "";
//       position: absolute;
//       left: 0;
//       bottom: 0;
//       width: 100%;
//       height: 100%;
//       background: linear-gradient(
//         0deg,
//         #141e30 -0.05%,
//         rgba(20, 30, 48, 0) 70%
//       );
//     }
//   }
//   .content-wrp {
//     position: absolute;
//     left: 11%;
//     bottom: 16%;
//     width: 58%;
//   }
//   @media screen and (max-width: 767px) {
//     .img-wrp {
//       height: 50vh;
//       img {
//         object-fit: cover;
//         height: 100%;
//       }
//     }
//     .content-wrp {
//       left: 0;
//       bottom: 20px;
//       width: 100%;
//       padding: 0 30px;
//       .heading-01 {
//         text-align: left;
//       }
//     }
//   }
// }
// // quality-ladders-section end

// // product-other-details start
// .product-other-details {
//   .container {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     flex-direction: row-reverse;
//     flex-wrap: wrap;
//     .img-wrp {
//       width: 37.7%;
//     }
//     .content-wrp {
//       width: 46%;
//     }
//   }
//   @media screen and (max-width: 767px) {
//     .container {
//       flex-direction: column-reverse;
//       .img-wrp {
//         width: 100%;
//       }
//       .content-wrp {
//         width: 100%;
//         margin-bottom: 20px;
//       }
//     }
//   }
// }
// // product-other-details end

// // our-company-section start
// .our-company-section {
//   .heading-01 {
//     width: 70vw;
//   }
//   .img-wrp {
//     display: flex;
//     align-items: center;
//     justify-content: flex-start;
//     z-index: -1;
//     position: relative;
//     .img-01 {
//       width: 50.5%;
//       margin: -3.7% -7.5% 0 11%;
//     }
//     .img-02 {
//       width: 38.6%;
//       margin-top: -3.7%;
//     }
//   }
//   .is-para {
//     width: 26%;
//     margin: -3% 8% 0 auto;
//   }
//   @media only screen and (max-width: 1700px) {
//     .is-para {
//       width: 30%;
//       margin: -4% 6% 0 auto;
//     }
//   }
//   @media only screen and (max-width: 1023px) {
//     .heading-01 {
//       width: 100%;
//       text-align: center;
//     }
//     .is-para {
//       width: 90%;
//       margin: 30px auto 0;
//       text-align: center;
//     }
//   }
//   @media only screen and (max-width: 767px) {
//     .img-wrp {
//       .img-01 {
//         width: 54.5%;
//         margin: -4.7% -7.5% 0 5%;
//       }
//       .img-02 {
//         width: 42.6%;
//         margin-top: -3.7%;
//       }
//     }
//   }
// }
// // our-company-section end

// // we-are-section start
// .we-are-section {
//   .heading-01 {
//     width: 28vw;
//     position: absolute;
//     left: 0;
//     top: 0;
//   }
//   .img-wrp {
//     text-align: center;
//     img {
//       width: 48%;
//       margin-left: 26%;
//     }
//   }
//   .content-area {
//     position: absolute;
//     right: 10%;
//     bottom: 0%;
//     width: 29%;
//     > div {
//       display: flex;
//       justify-content: space-between;
//       align-items: center;
//     }
//     > .is-para {
//       margin: 10% 13% 0 0;
//     }
//   }
//   @media only screen and (max-width: 1023px) {
//     .heading-01 {
//       position: relative;
//       text-align: center;
//       width: 100%;
//     }
//     .img-wrp {
//       margin: 40px 0;
//     }
//     .content-area {
//       position: static;
//       width: 340px;
//       margin: 0 auto;
//       > .is-para {
//         margin: 20px 0 0 0;
//       }
//     }
//   }
//   @media only screen and (max-width: 1023px) {
//     .heading-01 {
//       position: relative;
//       text-align: center;
//       width: 100%;
//     }
//     .img-wrp {
//       img {
//         width: 70%;
//         margin: 0 auto;
//       }
//     }
//     .content-area {
//       width: 300px;
//     }
//   }
//   @media only screen and (max-width: 767px) {
//     .img-wrp {
//       img {
//         width: 80%;
//       }
//     }
//     .content-area {
//       width: 240px;
//       > .is-para {
//         text-align: center;
//       }
//     }
//   }
// }
// // we-are-section end

// // know-about-section start
// .know-about-section {
//   // background: url('../../../assets/images/sw/know-about-bg.jpeg') center no-repeat;
//   background-size: cover;
//   padding: 4vw 0 0;
//   .heading-01 {
//     width: 28vw;
//     position: absolute;
//     left: 0;
//     top: -14%;
//   }
//   .founder-img {
//     img {
//       width: 33.8%;
//       margin: 15.2% 0 0 21%;
//     }
//   }
//   .content-area {
//     position: absolute;
//     right: 10%;
//     bottom: 50%;
//     width: 34%;
//   }
//   .know-about-three-img-wrp {
//     img {
//       width: 9.5vw;
//       // height: 9.5vw;
//       position: absolute;
//     }
//     .img-01 {
//       left: 0;
//       bottom: 37%;
//     }
//     .img-02 {
//       right: 17%;
//       bottom: -20px;
//     }
//     .img-03 {
//       right: 8%;
//       top: -40%;
//     }
//   }
//   @media screen and (max-width: 1023px) {
//     padding: 70px 0 0;
//     .heading-01 {
//       width: 100%;
//       text-align: center;
//       position: relative;
//     }
//     .founder-img {
//       margin: 40px 0;
//       img {
//         width: 36%;
//         margin: 0 auto;
//       }
//     }
//     .content-area {
//       position: static;
//       text-align: center;
//       width: 75%;
//       margin: 0 auto;
//     }
//     .know-about-three-img-wrp {
//       display: none;
//     }
//   }
//   @media screen and (max-width: 767px) {
//     .founder-img {
//       img {
//         display: none;
//       }
//     }
//     .content-area {
//       width: 95%;
//     }
//   }
// }
// // know-about-section end

// // Mission Vision section start
// .mission-vision-section {
//   .col-50-50-wrp {
//     > div {
//       &:first-child {
//         padding-right: 4vw;
//       }
//       &:last-child {
//         padding-left: 4vw;
//       }
//     }
//     .is-para {
//       padding-right: 10%;
//     }
//     &.swipe {
//       flex-direction: row-reverse;
//       > div {
//         &:first-child {
//           padding-right: 0;
//           padding-left: 4vw;
//         }
//         &:last-child {
//           padding-left: 0;
//           padding-right: 4vw;
//         }
//       }
//     }
//   }
//   @media screen and (max-width: 1023px) {
//     padding: 0 !important;
//     .col-50-50-wrp {
//       > div {
//         padding: 0 !important;
//         &:last-child {
//           .img-wrp {
//             display: none;
//           }
//         }
//       }
//       .heading-01,
//       .is-para {
//         text-align: center;
//         padding: 0 20px;
//       }
//     }
//   }
// }
// // Mission Vision section end

// // Our Team section start
// .our-team-section {
//   .heading-01 {
//     position: relative;
//     z-index: 1;
//   }
// }
// .team-wrp {
//   width: 76%;
//   margin: -1.2% 9% 0 auto;
//   display: flex;
//   flex-wrap: wrap;
//   position: relative;
//   z-index: 0;
//   &:before {
//     content: "";
//     position: absolute;
//     left: 5%;
//     top: -13%;
//     width: 90%;
//     height: 94%;
//     z-index: 0;
//     background-color: #e7f8ff;
//   }
//   .team {
//     margin: 0.5% 4px;
//     position: relative;
//     overflow: hidden;
//     width: calc((100% - 32px) / 4);
//     height: 303px;
//     z-index: 1;
//     .content-wrp {
//       position: absolute;
//       left: 0;
//       bottom: 0;
//       text-align: center;
//       width: 100%;
//       height: 100%;
//       display: flex;
//       flex-direction: column;
//       justify-content: flex-end;
//       > * {
//         position: relative;
//         z-index: 2;
//       }
//     }
//     &:nth-child(1) {
//       transform: translateY(0);
//       height: 326px;
//     }
//     &:nth-child(2) {
//       transform: translateY(-54%);
//     }
//     &:nth-child(3) {
//       transform: translateY(-14%);
//     }
//     &:nth-child(4) {
//       transform: translateY(-12%);
//       height: 363px;
//     }
//     &:nth-child(5) {
//       transform: translateY(-13%);
//     }
//     &:nth-child(6) {
//       transform: translateY(-51%);
//       height: 444px;
//     }
//     &:nth-child(7) {
//       transform: translateY(-35%);
//     }
//     &:nth-child(8) {
//       transform: translateY(-12%);
//       height: 386px;
//     }
//   }
//   @media screen and (max-width: 1700px) {
//     .team {
//       margin: 0.5% 4px;
//       width: calc((100% - 32px) / 4);
//       height: 15.8vw;
//       // height: 303px;
//       &:nth-child(1) {
//         transform: translateY(0);
//         height: 17vw;
//         // height: 312px;
//       }
//       &:nth-child(2) {
//         transform: translateY(-54%);
//       }
//       &:nth-child(3) {
//         transform: translateY(-14%);
//       }
//       &:nth-child(4) {
//         transform: translateY(-12%);
//         height: 18.9vw;
//         // height: 363px;
//       }
//       &:nth-child(5) {
//         transform: translateY(-13%);
//       }
//       &:nth-child(6) {
//         transform: translateY(-51%);
//         height: 22.9vw;
//         // height: 444px;
//       }
//       &:nth-child(7) {
//         transform: translateY(-35%);
//       }
//       &:nth-child(8) {
//         transform: translateY(-12%);
//         height: 20.1vw;
//         // height: 386px;
//       }
//     }
//   }
// }
// // Our Team section End

// //L3 gallery
// .l3-gallery {
//   > .container {
//     > .columns {
//       flex-wrap: wrap;
//     }
//   }
// }
// .gallery-wrp {
//   position: sticky;
//   top: 60px;
//   align-self: flex-start;
//   .thumbnail-slider-wrp {
//     width: 100px;
//   }
//   .image-slider-wrp {
//     width: calc(100% - 120px) !important;
//     margin-left: 120px;
//     .sale-tag {
//       &.is-absolute {
//         top: 1.4rem !important;
//       }
//     }
//   }
//   .owl-theme {
//     .owl-nav.disabled + .owl-dots {
//       position: absolute;
//       left: -120px;
//       top: 0;
//       width: 80px;
//       margin: 0;
//       .owl-dot {
//         width: 100%;
//         margin-bottom: 16px;
//         border: 1px solid transparent;
//         span {
//           width: 100%;
//           height: auto;
//           margin: 0;
//         }
//         &.active {
//           border-color: $blue;
//         }
//       }
//     }
//   }
//   @media screen and (max-width: 124px) {
//     width: 45% !important;
//     .thumbnail-slider-wrp {
//       width: 100px;
//       padding: 0;
//     }
//     .image-slider-wrp {
//       width: calc(100% + 0px) !important;
//       // margin-left: -100px;
//     }
//   }
//   @media screen and (max-width: 1023px) {
//     width: 100% !important;
//     position: relative;
//     top: 0;
//     > .columns {
//       display: flex;
//     }
//     .sale-tag.is-absolute {
//       right: -9px;
//     }
//   }
//   @media screen and (max-width: 480px) {
//     .image-slider-wrp {
//       width: 100% !important;
//       margin-left: 0;
//     }
//     .owl-theme {
//       .owl-nav.disabled + .owl-dots {
//         position: relative;
//         left: 0;
//         width: 100%;
//         .owl-dot {
//           margin-bottom: 0;
//           width: auto;
//           border: none;
//           img {
//             display: none;
//           }
//           span {
//             width: 10px;
//             height: 10px;
//             margin: 10px;
//           }
//         }
//       }
//     }
//   }
//   #sync2 {
//     &.owl-carousel {
//       .owl-stage {
//         width: 100% !important;
//         display: flex;
//         flex-wrap: wrap;
//       }
//       .owl-item {
//         width: 100% !important;
//         margin-bottom: 30px;
//       }
//       .owl-nav {
//         // display: flex;
//         justify-content: space-between;
//         position: absolute;
//         width: 100%;
//         top: calc(50% - 33px);
//         .owl-prev,
//         .owl-next {
//           font-size: 36px;
//           top: unset;
//           bottom: 15px;
//         }
//       }
//     }
//     @media screen and (max-width: 1024px) {
//       &.owl-carousel {
//         display: none !important;
//       }
//     }
//   }
//   #sync1 {
//     padding-left: 2rem;
//     &.owl-carousel {
//       .item {
//         width: 100%;
//       }
//     }
//     @media screen and (max-width: 1024px) {
//       padding-left: 0rem;
//     }
//   }
// }
// .product-detail-wrp {
//   .btn-big-cricle-wrp {
//     cursor: pointer;
//   }
//   @media screen and (max-width: 1024px) {
//     // width: 55% !important;
//     padding-left: 2rem !important;
//   }
//   @media screen and (max-width: 1023px) {
//     width: 100% !important;
//     padding-left: 0.75rem !important;
//     .is-flex-mobile {
//       .column {
//         flex-basis: inherit;
//         flex-grow: inherit;
//       }
//     }
//   }
// }
// /** Website Frontend CSS end */

// /** CK Editor start */
// .ck-editor__editable_inline {
//   min-height: 200px;
// }
// /** CK Editor end */
/* I start */
.clients-wrp .heading-wrp .heading-01 {
  text-align: center;
}
.clients-wrp .clients-logo .clients-logo-slider .item {
  width: 50%;
  margin: auto;
}

// .testimonials {
//   background: #f5f8ff;
// }
// .testimonials .heading-wrp .heading-01 {
//   text-align: center;
// }
// .testimonials .tstmnl-outer .tstmnl-slider .tstmnl-item {
//   display: flex;
//   background: #fff;
//   margin: auto;
//   box-shadow: 13px 34px 84px rgba(142, 142, 142, 0.2);
//   border-radius: 10px;
//   width: 1332px;
//   height: 387px;
// }
// .testimonials .tstmnl-outer .tstmnl-slider .tstmnl-item .image-wrp {
//   // width: 330px;
//   // height: 387px;
//   width: 55%;
//   height: 100%;
//   img {
//     height: 100%;
//     width: 100%;
//   }
// }
// .testimonials .tstmnl-outer .tstmnl-slider .tstmnl-item .tstmnl-content {
//   padding: 60px;
// }
// .testimonials
//   .tstmnl-outer
//   .tstmnl-slider
//   .tstmnl-item
//   .tstmnl-content
//   .content-wrp {
//   margin-top: 60px;
// }
// @media screen and (max-width: 1700px) {
//   .heading-01 {
//     font-size: 3vw;
//   }
//   .clients-wrp {
//     .clients-logo {
//       .clients-logo-slider {
//         .item {
//           width: 50%;
//           margin: auto;
//         }
//       }
//     }
//   }
//   .testimonials {
//     .tstmnl-outer {
//       .tstmnl-slider {
//         .tstmnl-item {
//           width: 1060px;
//           height: 292px;
//           .image-wrp {
//             // width: 250px;
//             // height: 292px;
//           }
//           .tstmnl-content {
//             padding: 40px;
//             .content-wrp {
//               margin-top: 30px;
//             }
//           }
//         }
//       }
//     }
//   }
//   .faqs {
//     .container {
//       .heading-wrp-02 {
//         .button {
//           padding: 27.5px 42.5px;
//         }
//       }
//     }
//   }
// }
// /*ibrhm-updt*/
// @media screen and (max-width: 1500px) {
//   .heading-01 {
//     font-size: 40px;
//   }
//   .clients-wrp {
//     padding: 60px 0;
//     .clients-logo {
//       .clients-logo-slider {
//         .item {
//           width: 40%;
//           margin: auto;
//         }
//       }
//     }
//   }
//   .testimonials {
//     padding: 4vw 0px;
//     .tstmnl-outer {
//       .tstmnl-slider {
//         .tstmnl-item {
//           width: 941px;
//           height: 222px;
//           .image-wrp {
//             // width: 190px;
//             // height: 222px;
//           }
//           .tstmnl-content {
//             padding: 30px;
//             .content-wrp {
//               margin-top: 20px;
//             }
//           }
//         }
//       }
//     }
//   }
//   .faqs {
//     padding: 50px 0;
//     .container {
//       .heading-wrp-02 {
//         .button {
//           padding: 25.5px 40.5px;
//         }
//       }
//     }
//   }
// }
// @media screen and (max-width: 1300px) {
//   .heading-01 {
//     font-size: 35px;
//   }
//   .clients-wrp {
//     padding: 50px 0;
//     .clients-logo {
//       .clients-logo-slider {
//         .item {
//           width: 35%;
//           margin: auto;
//         }
//       }
//     }
//   }
//   .testimonials {
//     padding: 40px 0;
//     .tstmnl-outer {
//       .tstmnl-slider {
//         .tstmnl-item {
//           width: 900px;
//           height: 195px;
//           .image-wrp {
//             // width: 165px;
//             // height: 192px;
//           }
//           .tstmnl-content {
//             padding: 25px;
//             .content-wrp {
//               margin-top: 20px;
//             }
//           }
//         }
//       }
//     }
//   }
//   .faqs {
//     padding: 40px 0;
//     .container {
//       .heading-wrp-02 {
//         .button {
//           padding: 25px 30px;
//         }
//       }
//       .top-bottom-padding {
//         padding-top: 35px;
//         padding-bottom: 35px;
//         .tab-accordion {
//           padding: 15px 15px;
//           margin-bottom: 35px;
//           .accordion-section-title {
//             .para-basic {
//               font-size: 19px;
//             }
//           }
//         }
//       }
//     }
//   }
// }
// @media screen and (max-width: 1024px) {
//   .heading-01 {
//     font-size: 30px;
//   }
//   .clients-wrp {
//     padding: 40px 0;
//     .clients-logo {
//       .clients-logo-slider {
//         .item {
//           width: 40%;
//           margin: auto;
//         }
//       }
//     }
//   }
//   .testimonials {
//     padding: 35px 0;
//     .tstmnl-outer {
//       .tstmnl-slider {
//         .tstmnl-item {
//           width: 720px;
//           height: 175px;
//           .image-wrp {
//             // width: 149px;
//             // height: 175px;
//           }
//           .tstmnl-content {
//             padding: 22px;
//             .para-big {
//               font-size: 18px;
//             }
//             .content-wrp {
//               margin-top: 20px;
//               .is-bold {
//                 font-size: 13px;
//               }
//               .is-gray {
//                 font-size: 10px;
//               }
//             }
//           }
//         }
//       }
//     }
//   }
//   .faqs {
//     padding: 30px 0;
//     .container {
//       .heading-wrp-02 {
//         .button {
//           padding: 22px 27px;
//         }
//       }
//       .top-bottom-padding {
//         padding-top: 30px;
//         padding-bottom: 30px;
//         .tab-accordion {
//           padding: 10px 10px;
//           margin-bottom: 30px;
//           .accordion-section-title {
//             .para-basic {
//               font-size: 17px;
//             }
//           }
//         }
//       }
//     }
//   }
// }
// @media screen and (max-width: 768px) {
//   .heading-01 {
//     font-size: 35px;
//   }
//   .clients-wrp {
//     padding: 35px 0;
//     .clients-logo {
//       .clients-logo-slider {
//         .item {
//           width: 30%;
//           margin: auto;
//         }
//       }
//     }
//   }
//   .testimonials {
//     padding: 40px 0;
//     .tstmnl-outer {
//       .tstmnl-slider {
//         .tstmnl-item {
//           width: 485px;
//           height: 124px;
//           .image-wrp {
//             // width: 105px;
//             // height: 123px;
//           }
//           .tstmnl-content {
//             padding: 15px;
//             .para-big {
//               // font-size: 12px;
//             }
//             .content-wrp {
//               margin-top: 12px;
//               .is-bold {
//                 font-size: 10px;
//               }
//               .is-gray {
//                 font-size: 8px;
//               }
//             }
//           }
//         }
//       }
//     }
//   }
//   .faqs {
//     padding: 20px 0;
//     .container {
//       .heading-wrp-02 {
//         .button {
//           padding: 12px 17px;
//           font-size: 15px;
//         }
//       }
//       .top-bottom-padding {
//         padding-top: 20px;
//         padding-bottom: 20px;
//         .tab-accordion {
//           padding: 5px 5px;
//           margin-bottom: 20px;
//           .accordion-section-title {
//             .para-basic {
//               font-size: 12px;
//             }
//           }
//         }
//       }
//     }
//   }
// }
// @media screen and (max-width: 480px) {
//   .heading-01 {
//     font-size: 25px;
//   }
//   .clients-wrp {
//     padding: 20px 0;
//     .clients-logo {
//       .clients-logo-slider {
//         .item {
//           width: 20%;
//           margin: auto;
//         }
//       }
//     }
//   }
//   .testimonials {
//     padding: 20px 0;
//     .tstmnl-outer {
//       .tstmnl-slider {
//         .tstmnl-item {
//           width: 330px;
//           height: 84px;
//           .image-wrp {
//             // width: 72px;
//             // height: 83px;
//           }
//           .tstmnl-content {
//             padding: 12px;
//             .para-big {
//               font-size: 8px;
//             }
//             .content-wrp {
//               margin-top: 10px;
//               .is-bold {
//                 font-size: 8px;
//               }
//               .is-gray {
//                 font-size: 5px;
//               }
//             }
//           }
//         }
//       }
//     }
//   }
//   .faqs {
//     padding: 20px 0;
//     .container {
//       .heading-wrp-02 {
//         .button {
//           padding: 15px 20px;
//           font-size: 10px;
//         }
//       }
//       .top-bottom-padding {
//         padding-top: 15px;
//         padding-bottom: 15px;
//         .tab-accordion {
//           padding: 2px 5px;
//           margin-bottom: 15px;
//           .accordion-section-title {
//             .para-basic {
//               font-size: 8px;
//             }
//           }
//         }
//       }
//     }
//   }
// }

/*Responsive end*/

/*about-us css start*/

// .about-css {
//   /*section-cmmn start*/
//   /*fonts start*/
//   .small {
//     font-size: 14px;
//     color: #6f6f6f;
//   }
//   .heading-02 {
//     font-size: 80px;
//     margin: 20px 0;
//     @media screen and (max-width: 1680px) {
//       font-size: 75px;
//     }
//     @media screen and (max-width: 1440px) {
//       font-size: 65px;
//     }
//     @media screen and (max-width: 480px) {
//       font-size: 30px;
//     }
//   }
//   .heading-01 {
//     font-size: 60px;
//     font-weight: 600;
//     line-height: 1.3;
//     @media screen and (max-width: 1680px) {
//       font-size: 50px;
//       line-height: 1.2;
//     }
//     @media screen and (max-width: 1440px) {
//       font-size: 45px;
//     }
//     @media screen and (max-width: 1366px) {
//       font-size: 40px;
//     }
//     @media screen and (max-width: 1280px) {
//       font-size: 35px;
//     }
//     @media screen and (max-width: 480px) {
//       font-size: 16px;
//     }
//   }
//   .para-medium {
//     font-size: 26px;
//     @media screen and (max-width: 1680px) {
//       font-size: 23px;
//       margin: 10px 0;
//     }
//     @media screen and (max-width: 1440px) {
//       font-size: 20px;
//     }
//     @media screen and (max-width: 1366px) {
//       font-size: 18px;
//     }
//     @media screen and (max-width: 1280px) {
//       font-size: 18px;
//     }
//     @media screen and (max-width: 480px) {
//       font-size: 12px;
//     }
//   }
//   /*fonts end*/
//   /*margin start*/
//   .small-margin {
//     margin-bottom: 30px;
//     @media screen and (max-width: 1680px) {
//       margin-bottom: 25px;
//     }
//     @media screen and (max-width: 1440px) {
//       margin-bottom: 15px;
//     }
//     @media screen and (max-width: 480px) {
//       margin-bottom: 5px;
//     }
//   }
//   .margin-02 {
//     margin: 28px 0;
//     @media screen and (max-width: 1680px) {
//       margin: 25px 0;
//     }
//     @media screen and (max-width: 1440px) {
//       margin: 15px 0;
//     }
//   }
//   /*margin end*/
//   /*section-cmmn end*/

//   /*banner start*/
//   .homePage_banner {
//     .cont-is-center {
//       padding: 165px 0;
//       justify-content: center;
//       .big-width {
//         align-items: center;
//         max-width: 870px;
//         .para-medium {
//           text-align: center;
//         }
//       }
//     }
//   }
//   /*banner end*/
//   /*companyvalues start */
//   .companyvalues {
//     .cont-is-center {
//       justify-content: space-between;
//       .medium-width {
//         max-width: 640px;
//         .para-medium {
//           width: 90%;
//           margin-top: 25px;
//         }
//       }
//     }
//   }
//   /*companyvalues end*/
//   /*ourvision start*/
//   .ourvision {
//     .cont-is-center {
//       justify-content: space-between;
//       padding-bottom: 115px;
//       width: 1380px;
//       .medium-width {
//         max-width: 600px;
//         padding-left: 20px;
//         .para-medium {
//           margin-top: 24px;
//         }
//       }
//     }
//     .ourmission {
//       padding: 0;
//       .banner-img-wrap {
//         padding-left: 20px;
//       }
//     }
//   }
//   /*ourvision end */
//   /*our-company start */
//   .our-company {
//     background: #f5f8ff;
//     .cont-is-center {
//       .small-width {
//         max-width: 430px;
//         .heading-01 {
//           line-height: 1.3;
//         }
//         .para-medium {
//           margin: 25px 0;
//         }
//       }
//       .card-wrap-01 {
//         .card-01 {
//           width: 39%;
//         }
//       }
//     }
//   }
//   /*our-company end */
//   /*class-services start */
//   .class-services {
//     .cont-is-center {
//       justify-content: space-between;
//       .medium-width {
//         max-width: 640px;
//         .heading-01 {
//           line-height: 1.3;
//         }
//         .para-medium {
//           width: 90%;
//           margin: 25px 0;
//         }
//       }
//     }
//   }
//   /*class-services end */
//   /* description start */
//   .description {
//     .container {
//       .content {
//         width: 93%;
//         margin-bottom: 55px;
//         .para-medium {
//           margin-bottom: 12px;
//         }
//         .para-medium-02 {
//           color: #555555;
//         }
//       }
//     }
//   }
//   /* description end */
//   /*about-us css end*/
//   /*about-responsive start*/
//   @media screen and (max-width: 1680px) {
//     .container {
//       &.fullhd {
//         max-width: 1450px;
//       }
//     }
//     /*homepage_banner start */
//     .homePage_banner {
//       .cont-is-center {
//         padding: 150px 0;
//         .big-width {
//           max-width: 770px;
//         }
//       }
//     }
//     /*homepage_banner end */

//     /*companyvalues start */
//     .companyvalues {
//       .cont-is-center {
//         .banner-img-wrap {
//           width: 50%;
//         }
//         .medium-width {
//           max-width: 570px;
//         }
//       }
//     }
//     /*companyvalues end */

//     /*ourvision start*/
//     .ourvision {
//       .cont-is-center {
//         padding-bottom: 100px;
//         justify-content: space-around;
//         .medium-width {
//           max-width: 510px;
//           padding-left: 0;
//           .para-medium {
//             margin-top: 20px;
//           }
//         }
//       }
//       /*ourvision end*/
//       /*ourmission start*/
//       .ourmission {
//         padding-bottom: 0;
//         .banner-img-wrap {
//           padding-left: 0;
//         }
//       }
//     }
//     /*ourvision end*/

//     /*our-company start*/
//     .our-company {
//       .cont-is-center {
//         .card-wrap-01 {
//           .card-01 {
//             /*width: 43%;
//             margin: 20px;
//             padding: 1.5vw;*/
//             .icon-img {
//               width: 15%;
//               img {
//                 width: 100%;
//               }
//             }
//           }
//         }
//       }
//     }
//     /*our-company end */

//     /*class-services start */
//     .class-services {
//       .cont-is-center {
//         justify-content: space-around;
//         .banner-img-wrap {
//           width: 45%;
//         }
//         .medium-width {
//           width: 480px;
//           .para-medium {
//             width: 108%;
//           }
//         }
//       }
//     }
//     /*class-services end */
//   }
//   @media screen and (max-width: 1440px) {
//     .container {
//       &.fullhd {
//         max-width: 1210px;
//       }
//     }
//     .homePage_banner {
//     }
//     /*companyvalues start */
//     .companyvalues {
//       .cont-is-center {
//         .medium-width {
//           max-width: 500px;
//           .para-medium {
//             width: 90%;
//           }
//         }
//       }
//     }
//     /*companyvalues end */

//     /*ourvision start*/
//     .ourvision {
//       .cont-is-center {
//         padding-bottom: 80px;
//         justify-content: space-around;
//         .medium-width {
//           max-width: 450px;
//           padding-left: 0;
//         }
//         .banner-img-wrap {
//           width: 45%;
//         }
//       }
//       /*ourvision end*/

//       /*ourmission start*/
//       .ourmission {
//         padding-bottom: 0;
//         .banner-img-wrap {
//           padding-left: 0;
//         }
//       }

//       /*ourvision end*/

//       /*our-company start*/
//       .our-company {
//         padding: 80px 0;
//         .cont-is-center {
//           max-width: 1440px;
//           width: 85%;
//           .small-width {
//             .para-medium {
//               width: 116%;
//             }
//           }
//           .card-wrap-01 {
//             .card-01 {
//               width: 40%;
//               margin: 15px;
//               padding: 20px;
//             }
//           }
//         }
//       }
//       /*our-company end */

//       /*class-services start */
//       .class-services {
//         padding: 80px 0;
//         .cont-is-center {
//           justify-content: space-around;
//           .banner-img-wrap {
//             width: 45%;
//           }
//           .medium-width {
//             width: 460px;
//             .para-medium {
//               width: 98%;
//             }
//           }
//         }
//       }
//       /*class-services end */

//       /*description start*/
//       .description {
//         padding-top: 120px;
//         padding-bottom: 65px;
//         .container {
//           .content {
//             width: 100%;
//             margin-bottom: 45px;
//           }
//         }
//       }
//       /*description end */
//     }
//   }
//   @media screen and (max-width: 1366px) {
//     /*companyvalues start */
//     .companyvalues {
//       .cont-is-center {
//         .banner-img-wrap {
//           width: 50%;
//         }
//         .medium-width {
//           max-width: 480px;
//           .para-medium {
//             width: 84%;
//           }
//         }
//       }
//     }
//     /*companyvalues end*/
//   }
//   @media screen and (max-width: 1280px) {
//     .homePage_banner {
//     }
//     /*companyvalues start*/
//     .companyvalues {
//       .cont-is-center {
//         justify-content: space-around;
//         .banner-img-wrap {
//           width: 45%;
//         }
//         .medium-width {
//           max-width: 420px;
//           .para-medium {
//             width: 100%;
//           }
//         }
//       }
//     }
//     /*companyvalues end*/

//     /*ourvision start*/
//     .ourvision {
//       .cont-is-center {
//         width: 80%;
//         padding-bottom: 50px;
//         justify-content: space-around;
//         .medium-width {
//           max-width: 400px;
//         }
//       }
//       /*ourvision end*/

//       /*ourmission start*/
//       .ourmission {
//         padding-bottom: 0;
//         .banner-img-wrap {
//           padding-left: 0;
//         }
//       }
//     }
//     /*ourvision end*/

//     /*choose-our-company start*/
//     .our-company {
//       .cont-is-center {
//         .small-width {
//           max-width: 280px;
//         }
//         .card-wrap-01 {
//           .card-01 {
//             width: 41%;
//           }
//         }
//       }
//     }
//     /*choose-our-company end*/

//     /*class-services start */
//     .class-services {
//       .cont-is-center {
//         .medium-width {
//           width: 370px;
//           .para-medium {
//             width: 110%;
//           }
//         }
//       }
//     }
//     /*class-services end */
//   }
//   @media screen and (max-width: 480px) {
//     /*banner start*/
//     .homePage_banner {
//       .cont-is-center {
//         padding: 10vw 0;
//       }
//     }
//     /*banner end*/
//     /*companyvalues start*/
//     .companyvalues {
//       .cont-is-center {
//         justify-content: space-around;
//         flex-direction: column;
//         .banner-img-wrap {
//           width: 80%;
//           padding-bottom: 6vw;
//         }
//         .medium-width {
//           max-width: 260px;
//           .para-medium {
//             width: 103%;
//             margin-top: 10px;
//           }
//         }
//       }
//     }
//     /*companyvalues end*/
//     /*ourvision start*/
//     .ourvision {
//       .cont-is-center {
//         flex-direction: column-reverse;
//         padding-bottom: 20px;
//         .medium-width {
//           max-width: 260px;
//           .para-medium {
//             margin-top: 10px;
//             width: 103%;
//           }
//         }
//         .banner-img-wrap {
//           width: 80%;
//           padding-bottom: 6vw;
//         }
//       }
//       /*ourvision end*/

//       /*ourmission start*/
//       .ourmission {
//         flex-direction: column;
//         padding-bottom: 0;
//         .banner-img-wrap {
//           padding-left: 0;
//         }
//       }
//     }
//     /*ourvision end*/
//     /*choose-our-company start*/
//     .our-company {
//       .cont-is-center {
//         flex-direction: column;
//         .small-width {
//           max-width: 260px;
//           .para-medium {
//             margin: 10px 0;
//           }
//         }
//         .card-wrap-01 {
//           width: 100%;
//           .card-01 {
//             width: 100%;
//             margin: 10px;
//             .icon-img {
//               width: 12%;
//               padding: 10px;
//             }
//           }
//         }
//       }
//     }
//     /*choose-our-company end*/
//     /*class-services start */
//     .class-services {
//       .cont-is-center {
//         flex-direction: column;
//         .banner-img-wrap {
//           padding-bottom: 6vw;
//           width: 80%;
//         }
//         .medium-width {
//           width: 260px;
//           margin: 0;
//           .para-medium {
//             margin: 10px 0;
//             width: 103%;
//           }
//         }
//       }
//     }
//     /*class-services end */
//     /* description start */
//     .description {
//       .container {
//         .content {
//           width: 100%;
//           margin-bottom: 15px;
//           .para-medium {
//             margin-bottom: 12px;
//           }
//           .para-medium-02 {
//             font-size: 12px;
//             color: #555555;
//           }
//         }
//       }
//     }
//     /* description end */
//   }
// }
/*about-responsive end*/
